import {
  LOADING,
  CREATE_CATEGORIES,
  GET_CATEGORIES,
  GET_SUB_SUB_CATEGORIES,
  SHOW_MENU,
  GET_SELLER_PRODUCT_LIST,
  GET_SUB_SUB_CATE_LIST,
  OTP_BOX,
  GET_BUSS_PRO,
  GET_PACKAGE_LIST,
  GET_SELLER_TANSACTION,
  GET_PROFILE,
  GET_SELLER_TICKET_LIST,
  GET_MESSAGE_LISTS,
  GET_INVOICE,
  GET_PRODUCT_DETAILS,
  GET_SELLER_PRODUCT_LIST_PUBLICE,
  GET_TAGS_PRODUCT_LIST_PUBLICE,
  GET_SELLER_PROFILE,
  GET_SELLER_PRODUCT_LIST_PUBLICE_BY_CATEGORY,
  GET_SUB_SUB_CATE_LIST_FIL,
  GET_PAGES,
  GET_EMAIL_QU,
  GET_WHATSAPP_QU,
  PAGE_DETAILS,
  SEARCH_TAGS,
  SEARCH_PRODUCT_PUB,
  OTP_BOX_FORGOT,
  GET_FOOTER_DATA_ALL,
  GET_home_ALL_PRO,
  TOP_VIEW_ALL_TYPE,
  SELLER_LIST,
  DESHBOARD_STATE,
  SLIDER_LIST,
  BLOG_LIST,
  BLOG_DETAILS,
  GET_EXPIRE_DATE,
  home_POPS,
  FAQS_LIST,
  COUNTRY_LIST,
  LOADING_1,
  CITY_LIST,
  USER_PRODUCT_SERACH,
  GET_BUSS_PRO_1,
  ADD_CART_ITME,
  USER_PROFILE,
  CREATE_ADDRESS,
  ORDER_DETIALS,
  LOADING_CHECKOUT,
  SELLER_UPDATE_DETAILS,
  SELLER_DATE_REPORTS,
  MY_DIRECT_TEAM,
  MY_INCOME_LIST,
  USER_INCOME_DATE,
  ALL_TEAM,
  WEEK_DATA,
  DAY_DATA,
  TEAM_COUNT,
  CHALLANGE_DATA,
  GET_SUB_SUB_CATEGORY_LISTS,
  GET_PRODUCT_LISTS,
  GET_LISTING_LISTS,
  GET_LISTING_DETAILS,
  GET_SELLER_ALL_LIST,
  DESHBOARD,
  VENDOR_ORDER,
  LOST_AND_FOUND_PUBLICE_LIST,
  GET_VENDOR_PROFILES,
  GET_ALL_EDUCATION_CATEGORY,
  GET_ALL_EDUCATION_LIST,
  GET_MARQUES_LIST,
  GET_USE_LOST_AND_FOUND_LIST,
  GET_USER_CERTIFICITE_LIST,
  GET_CATEGORIES_FILTER,
  GET_STUDENT_VIDEO_LIST,
  GET_OUR_WORK_LIST,
  GET_VIDEO_WITH_PAGI_LIST,
  GET_Like_LIST,
  GET_PAYABLE_AMOUNT_LIST,
  GET_WITHDRAWAL_REQUEST_LIST,
  GET_ACCOUNT_PAYMENT,
  MESSAGE_PUBLICE_LIST,
  GET_JOB_PORTAL_ALL,
  GET_SUB_CATEGORY_ALL_LISTS,
  GET_MAIN_ALL_CATEGORY,
  GET_JOB_CATEGORY_PORTAL_ALL,
  GET_LOST_AND_FOUND_PU_DETAILS,
  GET_LOST_AND_FOUND_LIST_DETAILS,
  GET_RESUME_USER,
  GET_USER_APPLIST_JOB_LIST,
  GET_VENDOR_CREATED_JOBS,
  GET_JOBS_DETAILS,
  GET_JOBS_DETAILS_RELETED,
  GET_ALL_BRAND,
  BLOG,
  MARRIAGE_LIST,
  MARRIAGE_DETAILS,
  GET_RECENT_BLOG_LIST,
  GET_BLOG_CATEGOY_LIST,
  USER_VENDOR_BLOG_SERACH,
  GET_MERRIAGE_PROFILE,
  SLIDER_LIST2,
} from "../common/constant";

const initialState = {
  loading: false,
  loading1: false,
  loading_checkOut: false,
  all_categories_List: [],
  all_sub_sub_categories_home_List: {},
  all_sub_sub_categ_List: [],
  all_sub_sub_categ_List_fil: [],
  all_message_List: [],
  menu_show: false,
  otp_box: false,
  get_buss_pro: {},
  get_buss_pro_1: {},
  get_pages: [],
  get_search_tags_list: [],
  all_categories_List_admin: {},
  categories_status_update: {},
  categories_delete: {},
  create_categories: {},
  get_seller_product_list: {},
  get_package_list: {},
  get_seller_transaction_list: {},
  get_seller_ticket_list: {},
  get_seller_ticket_list_public: {},
  get_tags_ticket_list_public: {},
  get_seller_product_list_public_by_cate: {},
  get_profile: {},
  get_product_details: {},
  get_seller_details: {},
  get_email_qui: {},
  get_whatsapp_qui: {},
  get_page_details: {},
  get_footer_all_data: {},
  deshboard_state: {},
  slider_list: {},
  blog_list: {},
  blog_details: {},
  get_expire_date: {},
  home_pop: {},
  serach_pro_pub: [],
  get_home_all_pro: {},
  user_product_search: {},
  get_user_profile: {},
  order_details: {},
  seller_update_details: {},
  seller_date_reports: {},
  my_direct_team: {},
  user_income_date: {},
  user_all_team: {},
  team_count: {},
  challange_data: {},
  get_product_list: {},
  get_listing_list: {},
  get_listing_details: {},
  get_seller_all_list: {},
  deshboard: {},
  vendor_order_list: {},
  lost_and_found_pub: {},
  get_vendor_profiles: {},
  get_marques_List: {},
  seller_list_public: {},
  get_user_lost_and_found_List: {},
  get_user_ceritificate_List: {},
  get_invoice: {},
  get_student_video: {},
  get_our_work_list: {},
  get_video_pagi_list: {},
  get_like_list: {},
  get_resume_user: {},
  get_payable_list_list: {},
  get_withdrawal_requset_list: {},
  get_job_portal_all: {},
  get_account_payment: {},
  get_job_category_portal_all: {},
  get_lost_and_Found_pub_details: {},
  get_user_apply_list_job: {},
  get_blog: {},
  grt_jobs_details: {},
  get_merriage_profile: {},
  top_view_all_typer: [],
  faqs_list: [],
  grt_all_brand: [],
  country_list: [],
  city_list: [],
  get_all_cart_itme: [],
  get_address: [],
  my_income_list: [],
  week_data: [],
  day_data: [],
  get_sub_sub_category_list: [],
  get_All_edu_Category: [],
  get_All_edu_List: [],
  get_category_Filter: [],
  get_sub_sub_all_category: [],
  get_main_all_category: [],
  grt_jobs_details_releted: [],
  get_vendor_created_jobs: {},
  get_marriage_list: {},
  get_marriage_details: {},
  get_blog_category_list: [],
  get_recent_blog_list: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: action.payload };
    case LOADING_1:
      return { ...state, loading1: action.payload };
    case CREATE_CATEGORIES:
      return { ...state, create_categories: action.payload };
      case GET_BLOG_CATEGOY_LIST:
        return { ...state, get_blog_category_list: action.payload };
    case GET_CATEGORIES:
      return { ...state, all_categories_List: action.payload };
    case GET_SUB_SUB_CATEGORIES:
      return { ...state, all_sub_sub_categories_home_List: action.payload };
    case SHOW_MENU:
      return { ...state, menu_show: action.payload };
    case GET_SELLER_PRODUCT_LIST:
      return { ...state, get_seller_product_list: action.payload };
    case GET_SUB_SUB_CATE_LIST:
      return { ...state, all_sub_sub_categ_List: action.payload };
    case OTP_BOX:
      return { ...state, otp_box: action.payload };
    case GET_BUSS_PRO:
      return { ...state, get_buss_pro: action.payload };
    case GET_BUSS_PRO_1:
      return { ...state, get_buss_pro_1: action.payload };
    case GET_PACKAGE_LIST:
      return { ...state, get_package_list: action.payload };
    case GET_SELLER_TANSACTION:
      return { ...state, get_seller_transaction_list: action.payload };
    case GET_PROFILE:
      return { ...state, get_profile: action.payload };
    case GET_SELLER_TICKET_LIST:
      return { ...state, get_seller_ticket_list: action.payload };
    case GET_MESSAGE_LISTS:
      return { ...state, all_message_List: action.payload };
    case GET_INVOICE:
      return { ...state, get_invoice: action.payload };
    case GET_PRODUCT_DETAILS:
      return { ...state, get_product_details: action.payload };
    case GET_SELLER_PRODUCT_LIST_PUBLICE:
      return { ...state, get_seller_ticket_list_public: action.payload };
    case GET_SELLER_PROFILE:
      return { ...state, get_seller_details: action.payload };
    case GET_SELLER_PRODUCT_LIST_PUBLICE_BY_CATEGORY:
      return {
        ...state,
        get_seller_product_list_public_by_cate: action.payload,
      };
    case GET_SUB_SUB_CATE_LIST_FIL:
      return { ...state, all_sub_sub_categ_List_fil: action.payload };
    case GET_PAGES:
      return { ...state, get_pages: action.payload };
    case GET_EMAIL_QU:
      return { ...state, get_email_qui: action.payload };
    case GET_WHATSAPP_QU:
      return { ...state, get_whatsapp_qui: action.payload };
    case PAGE_DETAILS:
      return { ...state, get_page_details: action.payload };
    case SEARCH_TAGS:
      return { ...state, get_search_tags_list: action.payload };
    case GET_TAGS_PRODUCT_LIST_PUBLICE:
      return { ...state, get_tags_ticket_list_public: action.payload };
    case SEARCH_PRODUCT_PUB:
      return { ...state, serach_pro_pub: action.payload };
    case GET_FOOTER_DATA_ALL:
      return { ...state, get_footer_all_data: action.payload };
    case GET_home_ALL_PRO:
      return { ...state, get_home_all_pro: action.payload };
    case TOP_VIEW_ALL_TYPE:
      return { ...state, top_view_all_typer: action.payload };
    case SELLER_LIST:
      return { ...state, seller_list_public: action.payload };
    case DESHBOARD_STATE:
      return { ...state, deshboard_state: action.payload };
    case SLIDER_LIST:
      return { ...state, slider_list: action.payload };
    case SLIDER_LIST2:
      return { ...state, slider_list2: action.payload };
    case BLOG_LIST:
      return { ...state, blog_list: action.payload };
    case BLOG_DETAILS:
      return { ...state, blog_details: action.payload };
    case GET_EXPIRE_DATE:
      return { ...state, get_expire_date: action.payload };
    case home_POPS:
      return { ...state, home_pop: action.payload };
    case FAQS_LIST:
      return { ...state, faqs_list: action.payload };
    case COUNTRY_LIST:
      return { ...state, country_list: action.payload };
    case CITY_LIST:
      return { ...state, city_list: action.payload };
    case USER_PRODUCT_SERACH:
      return { ...state, user_product_search: action.payload };
    case USER_VENDOR_BLOG_SERACH:
      return { ...state, user_vendor_blog_search: action.payload };
    case ADD_CART_ITME:
      return { ...state, get_all_cart_itme: action.payload };
    case USER_PROFILE:
      return { ...state, get_user_profile: action.payload };
    case CREATE_ADDRESS:
      return { ...state, get_address: action.payload };
    case ORDER_DETIALS:
      return { ...state, order_details: action.payload };
    case LOADING_CHECKOUT:
      return { ...state, loading_checkOut: action.payload };
    case SELLER_UPDATE_DETAILS:
      return { ...state, seller_update_details: action.payload };
    case SELLER_DATE_REPORTS:
      return { ...state, seller_date_reports: action.payload };
    case MY_DIRECT_TEAM:
      return { ...state, my_direct_team: action.payload };
    case MY_INCOME_LIST:
      return { ...state, my_income_list: action.payload };
    case USER_INCOME_DATE:
      return { ...state, user_income_date: action.payload };
    case ALL_TEAM:
      return { ...state, user_all_team: action.payload };
    case WEEK_DATA:
      return { ...state, week_data: action.payload };
    case DAY_DATA:
      return { ...state, day_data: action.payload };
    case TEAM_COUNT:
      return { ...state, team_count: action.payload };
    case CHALLANGE_DATA:
      return { ...state, challange_data: action.payload };
    case GET_SUB_SUB_CATEGORY_LISTS:
      return { ...state, get_sub_sub_category_list: action.payload };
    case GET_PRODUCT_LISTS:
      return { ...state, get_product_list: action.payload };
    case GET_LISTING_LISTS:
      return { ...state, get_listing_list: action.payload };
    case GET_LISTING_DETAILS:
      return { ...state, get_listing_details: action.payload };
    case GET_SELLER_ALL_LIST:
      return { ...state, get_seller_all_list: action.payload };
    case DESHBOARD:
      return { ...state, deshboard: action.payload };
    case VENDOR_ORDER:
      return { ...state, vendor_order_list: action.payload };
    case LOST_AND_FOUND_PUBLICE_LIST:
      return { ...state, lost_and_found_pub: action.payload };
    case GET_VENDOR_PROFILES:
      return { ...state, get_vendor_profiles: action.payload };
    case GET_ALL_EDUCATION_CATEGORY:
      return { ...state, get_All_edu_Category: action.payload };
    case GET_ALL_EDUCATION_LIST:
      return { ...state, get_All_edu_List: action.payload };
    case GET_MARQUES_LIST:
      return { ...state, get_marques_List: action.payload };
    case GET_USE_LOST_AND_FOUND_LIST:
      return { ...state, get_user_lost_and_found_List: action.payload };
    case GET_USER_CERTIFICITE_LIST:
      return { ...state, get_user_ceritificate_List: action.payload };
    case GET_CATEGORIES_FILTER:
      return { ...state, get_category_Filter: action.payload };
    case GET_STUDENT_VIDEO_LIST:
      return { ...state, get_student_video: action.payload };
    case GET_OUR_WORK_LIST:
      return { ...state, get_our_work_list: action.payload };
    case GET_VIDEO_WITH_PAGI_LIST:
      return { ...state, get_video_pagi_list: action.payload };
    case GET_Like_LIST:
      return { ...state, get_like_list: action.payload };
    case GET_PAYABLE_AMOUNT_LIST:
      return { ...state, get_payable_list_list: action.payload };
    case GET_WITHDRAWAL_REQUEST_LIST:
      return { ...state, get_withdrawal_requset_list: action.payload };
    case GET_ACCOUNT_PAYMENT:
      return { ...state, get_account_payment: action.payload };
    case MESSAGE_PUBLICE_LIST:
      return { ...state, get_message_list: action.payload };
    case GET_JOB_PORTAL_ALL:
      return { ...state, get_job_portal_all: action.payload };
    case GET_SUB_CATEGORY_ALL_LISTS:
      return { ...state, get_sub_sub_all_category: action.payload };
    case GET_MAIN_ALL_CATEGORY:
      return { ...state, get_main_all_category: action.payload };
    case GET_JOB_CATEGORY_PORTAL_ALL:
      return { ...state, get_job_category_portal_all: action.payload };
    case GET_LOST_AND_FOUND_PU_DETAILS:
      return { ...state, get_lost_and_Found_pub_details: action.payload };
    case GET_LOST_AND_FOUND_LIST_DETAILS:
      return { ...state, get_lost_and_Found_pub_details_list: action.payload };
    case GET_RESUME_USER:
      return { ...state, get_resume_user: action.payload };
    case GET_USER_APPLIST_JOB_LIST:
      return { ...state, get_user_apply_list_job: action.payload };
    case GET_VENDOR_CREATED_JOBS:
      return { ...state, get_vendor_created_jobs: action.payload };
    case GET_JOBS_DETAILS:
      return { ...state, grt_jobs_details: action.payload };
    case GET_JOBS_DETAILS_RELETED:
      return { ...state, grt_jobs_details_releted: action.payload };
    case GET_ALL_BRAND:
      return { ...state, grt_all_brand: action.payload };

    case BLOG:
      return { ...state, get_blog: action.payload };
    case MARRIAGE_LIST:
      return { ...state, get_marriage_list: action.payload };
    case MARRIAGE_DETAILS:
      return { ...state, get_marriage_details: action.payload };
      case GET_RECENT_BLOG_LIST:
        return { ...state, get_recent_blog_list: action.payload };
      case GET_MERRIAGE_PROFILE:
        return { ...state, get_merriage_profile: action.payload };
    // case OTP_BOX_FORGOT:
    //   return { ...state, serach_pro_pub: action.payload };

    default:
      return state;
  }
};
