import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { allapiAction } from "../../Redux/common/action";

function WeddingHeader() {
  const [value, setValue] = useState("");
  const [valuevendor, setValuevendor] = useState("");
  const [valuesetUserDeatils, setUserDeatils] = useState({});
  useEffect(() => {
    if (typeof window !== "undefined") {
      setValue(localStorage.getItem("access_token"));
      setValuevendor(localStorage.getItem("access_token_vendor"));

      const userDetails = localStorage.getItem("access_user");

      setUserDeatils(JSON?.parse(userDetails));
    } else {
      setValue("");
      setValuevendor("");
      setUserDeatils({});
    }
  }, []);

  const router = useLocation();
  const { pathname } = router;
  const splitLocation = pathname.split("/");

  console.log(splitLocation);
  console.log(splitLocation[2]);
  const [profileData, setprofileData] = useState({});
  const dispatch = useDispatch();

  console.log(profileData);

  useEffect(() => {
    // getmarriageProfile
    getProfileData();
    return () => {};
  }, []);

  const getProfileData = async () => {
    const response = await dispatch(allapiAction.getmarriageProfile({}));

    console.log(response);

    setprofileData(response?.data);

    //  setCroppedImageUrl
  };

  return (
    <div className="weddingportal">
      <section id="top" className="bg_brown pt-2 pb-2">
        <div className="container-xl">
          <div className="row top_1">
            <div className="col-md-6">
              <div className="top_1l mt-1">
                <ul className="mb-0 font_14">
                  <li className="text-white d-inline-block">
                    <i className="fa fa-phone me-1 align-middle col_dark"></i>{" "}
                    +91 - 9610255402
                  </li>
                  <li className="text-white d-inline-block ms-3">
                    <i className="fa fa-envelope me-1 align-middle col_dark"></i>{" "}
                    info@bestBharat.com
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className="top_1r text-end">
                <ul className="mb-0">
                  <li className="d-inline-block">
                    <a
                      className="d-inline-block text-center text-white"
                      href="#"
                    >
                      <i className="fa-brands fa-facebook"></i>
                    </a>
                  </li>
                  <li className="d-inline-block">
                    <a
                      className="d-inline-block text-center text-white"
                      href="#"
                    >
                      <i className="fa-brands fa-instagram"></i>
                    </a>
                  </li>
                  <li className="d-inline-block">
                    <a
                      className="d-inline-block text-center text-white"
                      href="#"
                    >
                      <i className="fa-brands fa-pinterest"></i>
                    </a>
                  </li>
                  <li className="d-inline-block">
                    <a
                      className="d-inline-block text-center text-white"
                      href="#"
                    >
                      <i className="fa-brands fa-linkedin"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="header">
        <nav
          className="navbar navbar-expand-md navbar-light p-0 pt-2 pb-2"
          id="navbar_sticky"
        >
          <div className="container-xl">
            <Link
              className="p-0 navbar-brand fw-bold me-0 family_1 col_red"
              to="/wedding"
            >
              <i className="fa fa-heart me-1 col_brown"></i>Best Bharat
              Matrimonial
              <br />
              <span className="family_2 fw-normal font_14 col_brown">
                Best Bharat Matrimony Website
              </span>
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav mb-0 ms-auto">
                <li className="nav-item">
                  <Link
                    className={
                      splitLocation[2] == undefined
                        ? "nav-link active"
                        : "nav-link "
                    }
                    aria-current="page"
                    to="/wedding"
                  >
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={
                      splitLocation[2] == "about"
                        ? "nav-link active"
                        : "nav-link "
                    }
                    to="/wedding/about"
                  >
                    About
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={
                      splitLocation[2] == "plans"
                        ? "nav-link active"
                        : "nav-link "
                    }
                    to="/wedding/plans"
                  >
                    Membership
                  </Link>
                </li>
                {profileData && (
                  <li className="nav-item">
                    <Link
                      className={
                        splitLocation[2] == "matching-list"
                          ? "nav-link active"
                          : "nav-link "
                      }
                      to="/wedding/matching-list"
                    >
                      Matching
                    </Link>
                  </li>
                )}

                {/* <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Events
                  </a>
                  <ul
                    className="dropdown-menu drop_1"
                    aria-labelledby="navbarDropdown"
                  >
                    <li>
                      <a className="dropdown-item" href="event.html">
                        Events
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item border-0"
                        href="event_detail.html"
                      >
                        Events Detail
                      </a>
                    </li>
                  </ul>
                </li> */}

                {/* <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Pages
                  </a>
                  <ul
                    className="dropdown-menu drop_1"
                    aria-labelledby="navbarDropdown"
                  >
                    <li>
                      <a className="dropdown-item" href="story.html">
                        Story
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="team.html">
                        Team
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item border-0" href="gallery.html">
                        Gallery
                      </a>
                    </li>
                  </ul>
                </li> */}
                {/* <li className="nav-item">
                  <a className="nav-link" href="contact.html">
                    Contact
                  </a>
                </li> */}
                <li className="nav-item">
                  <Link
                    className={
                      splitLocation[2] == "list"
                        ? "nav-link active"
                        : "nav-link "
                    }
                    to="/wedding/list"
                  >
                    Search
                  </Link>
                </li>
                {value || valuevendor ? (
                  ""
                ) : (
                  <li className="nav-item">
                    <Link className="nav-link" to="/login">
                      Login
                    </Link>
                  </li>
                )}
              </ul>
              {/* <ul className="navbar-nav mb-0 ms-auto">
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle dropdown_search nav_hide fs-5 lh-1"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="fa fa-search"></i>
                  </a>
                  <ul
                    className="dropdown-menu drop_2 p-3"
                    aria-labelledby="navbarDropdown"
                  >
                    <li>
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control bg_light border-0 font_13"
                          placeholder="Enter Keyword"
                        />
                        <span className="input-group-btn">
                          <button
                            className="btn btn-primary bg_brown p-3 px-4 border-0"
                            type="button"
                          >
                            <i className="fa fa-search"></i>
                          </button>
                        </span>
                      </div>
                    </li>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle drop_togn nav_hide fs-5 lh-1"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="fa fa-shopping-cart"></i>
                  </a>
                  <ul
                    className="dropdown-menu drop_cart rounded-0 border-0"
                    aria-labelledby="navbarDropdown"
                  >
                    <li>
                      <div className="drop_1i row">
                        <div className="col-md-6 col-6">
                          <div className="drop_1il">
                            <h5 className="fs-6">2 ITEMS</h5>
                          </div>
                        </div>
                        <div className="col-md-6 col-6">
                          <div className="drop_1il text-end">
                            <h5 className="fs-6">
                              <a href="cart.html">VIEW CART</a>
                            </h5>
                          </div>
                        </div>
                      </div>
                      <div className="drop_1i1 row">
                        <div className="col-md-6 col-6">
                          <div className="drop_1i1l">
                            <h6 className="fs-6">
                              <a href="#">Nulla Quis</a> <br />{" "}
                              <span className="d-inline-block mt-1 font_15">
                                1x - $89.00
                              </span>
                            </h6>
                          </div>
                        </div>
                        <div className="col-md-4 col-4">
                          <div className="drop_1i1r">
                            <a href="#">
                              <img
                                src="/img/wedding/52.jpg"
                                className="w-100"
                                alt="abc"
                              />
                            </a>
                          </div>
                        </div>
                        <div className="col-md-2 col-2">
                          <div className="drop_1i1l text-end">
                            <h6>
                              <span>
                                <i className="fa fa-trash"></i>
                              </span>
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div className="drop_1i1 row">
                        <div className="col-md-6 col-6">
                          <div className="drop_1i1l">
                            <h6 className="fs-6">
                              <a href="#">Eget Nulla</a> <br />{" "}
                              <span className="d-inline-block mt-1 font_15">
                                1x - $49.00
                              </span>
                            </h6>
                          </div>
                        </div>
                        <div className="col-md-4 col-4">
                          <div className="drop_1i1r">
                            <a href="#">
                              <img
                                src="/img/wedding/53.jpg"
                                className="w-100"
                                alt="abc"
                              />
                            </a>
                          </div>
                        </div>
                        <div className="col-md-2 col-2">
                          <div className="drop_1i1l text-end">
                            <h6>
                              <span>
                                <i className="fa fa-trash"></i>
                              </span>
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div className="drop_1i2 row">
                        <div className="col-md-6 col-6">
                          <div className="drop_1il">
                            <h5 className="fs-6">TOTAL</h5>
                          </div>
                        </div>
                        <div className="col-md-6 col-6">
                          <div className="drop_1il text-end">
                            <h5 className="fs-5">$142.00</h5>
                          </div>
                        </div>
                      </div>
                      <div className="drop_1i3 text-center row">
                        <div className="col-md-12 col-12">
                          <ul className="mb-0">
                            <li className="d-inline-block mx-1">
                              <a
                                className="button_2 px-3 pt-2 pb-2 font_14"
                                href="#"
                              >
                                View Order
                              </a>
                            </li>
                            <li className="d-inline-block mx-1">
                              <a
                                className="button_1 px-3 pb-2 pt-2 font_14"
                                href="#"
                              >
                                Checkout
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul> */}
            </div>
          </div>
        </nav>
      </section>
    </div>
  );
}

export default WeddingHeader;
