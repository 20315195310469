// import { allapiAction } from "@/Redux/common/action";
// import { URL } from "@/Redux/common/url";
// import Link from "next/link";
import React, { useEffect, useLayoutEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import CoustomImg from "../CoustomImg/CoustomImg";
import HomeSlider from "../Home/HomeSlider";
import OurWork from "../OurWork/OurWork";
import moment from "moment";
import { allapiAction } from "../../Redux/common/action";
import { URL } from "../../Redux/common/url";
import TruncateText from "../TruncateText/TruncateText";
import { AsyncPaginate } from "react-select-async-paginate";
function Soksandhesh() {
  // lostfoundlistpublic

  const lost_and_found_pub = useSelector((state) =>
    state?.allapi?.get_message_list ? state?.allapi?.get_message_list : {}
  );
  const lostfoundList = lost_and_found_pub?.docs ? lost_and_found_pub?.docs : 0;
  const [page, setPage] = useState(1);
  const [category, setCategory] = useState("");
  const [state, setState] = useState("");
  const [District, setDistrict] = useState("");
  const [Citys, setCity] = useState("");
  const [CitysList, setCitysList] = useState([]);
  const [pincode, setpincode] = useState("");
  const [search, setsearch] = useState("");
  const [gender, setgender] = useState("");
  console.log(lost_and_found_pub);
  const dispatch = useDispatch();
  // const allCountry = Country.getAllCountries();
  // const allStates = State.getAllStates();

  // // console.log(allStates);
  // console.log(CitysList);
  // const statesindia =
  //   allStates &&
  //   allStates?.filter((data) => {
  //     return data.countryCode == "IN";
  //   });
  // console.log(statesindia);
  // useEffect(() => {
  //   if (state) {
  //     console.log("sfdsfdskjfnsdnfn");
  //     // const statecode =
  //     //   statesindia &&
  //     //   statesindia?.filter((data) => {
  //     //     return data.name == state;
  //     //   });

  //     // console.log(state);
  //     const statecode = statesindia.find((data) => data.name == state);
  //     // console.log(statecode);
  //     const cityData = City.getCitiesOfState("IN", statecode?.isoCode);

  //     console.log(cityData);
  //     setCitysList(cityData);
  //   }
  // }, [state]);

  // console.log(statesindia);
  // console.log(allCity);
  useEffect(() => {
    dispatch(
      allapiAction.messagelistpublic({ page: 1, category: "condolenceMessage" })
    );
    // dispatch(allapiAction.getsliderList({ page: "lostandfound" }));
  }, []);
  const [cityselect, setcityselect] = useState(null);
  useEffect(() => {
    if (cityselect) {
      dispatch(
        allapiAction.messagelistpublic({
          page: 1,
          category: "condolenceMessage",
          location: cityselect?._id,
        })
      );
    } else {
      dispatch(
        allapiAction.messagelistpublic({
          page: 1,
          category: "condolenceMessage",
        })
      );
    }

    // dispatch(allapiAction.getsliderList({ page: "lostandfound" }));
  }, [cityselect]);

  const loadOptionsPalyer = async (search, loadedOptions, pages) => {
    console.log(loadedOptions);
    console.log(pages);
    const response = await fetch(
      URL.API_BASE_URL +
        `/publicApi/searchareaforproduct?key=${search}&page=${pages?.page}`
    );
    const responseJSON = await response.json();

    const options = responseJSON?.results?.map((data) => {
      data.value = data?._id;
      data.label =
        data?.Area +
        ", " +
        data?.District +
        ", " +
        data?.State +
        ", " +
        data?.Pincode;

      return data;
    });

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: pages?.page + 1,
      },
    };
  };

  const hendletosearche = (e) => {
    setsearch(e);
    // lostfoundlistsearchpublic
    setPage(1);

    var fanalquery = { page: page, key: e };

    if (category) {
      fanalquery.category = category;
    }
    if (state) {
      fanalquery.state = state;
    }
    if (District) {
      fanalquery.District = District;
    }
    if (Citys) {
      fanalquery.City = Citys;
    }
    if (pincode) {
      fanalquery.pincode = pincode;
    }

    dispatch(allapiAction.lostfoundlistsearchpublic(fanalquery));
  };
  const hendletofiltercate = (e) => {
    setCategory(e);
    setPage(1);

    var fanalquery = { page: page, category: e };

    if (search) {
      fanalquery.key = search;
    }
    if (state) {
      fanalquery.state = state;
    }
    if (District) {
      fanalquery.District = District;
    }
    if (Citys) {
      fanalquery.City = Citys;
    }
    if (pincode) {
      fanalquery.pincode = pincode;
    }
    dispatch(allapiAction.lostfoundlistsearchpublic(fanalquery));
  };
  const hendletofilterstate = (e) => {
    setState(e);
    setPage(1);

    var fanalquery = { page: page, state: e };
    if (category) {
      fanalquery.category = category;
    }
    if (search) {
      fanalquery.key = search;
    }
    // if (state) {
    //   fanalquery.state = state;
    // }
    if (District) {
      fanalquery.District = District;
    }
    if (Citys) {
      fanalquery.City = Citys;
    }
    if (pincode) {
      fanalquery.pincode = pincode;
    }
    dispatch(allapiAction.lostfoundlistsearchpublic(fanalquery));
  };
  const hendletofilterdis = (e) => {
    setDistrict(e);
    setPage(1);
    setgender("");

    var fanalquery = { page: page, District: e };
    if (category) {
      fanalquery.category = category;
    }
    if (search) {
      fanalquery.key = search;
    }
    if (state) {
      fanalquery.state = state;
    }
    // if (District) {
    //   fanalquery.District = District;
    // }
    if (Citys) {
      fanalquery.City = Citys;
    }
    if (pincode) {
      fanalquery.pincode = pincode;
    }
    dispatch(allapiAction.lostfoundlistsearchpublic(fanalquery));
  };
  const hendletofilterdisgender = (e) => {
    // setDistrict(e);
    setgender(e);
    setPage(1);

    var fanalquery = { page: page, gender: e };
    if (category) {
      fanalquery.category = category;
    }
    if (search) {
      fanalquery.key = search;
    }
    if (state) {
      fanalquery.state = state;
    }
    if (District) {
      fanalquery.District = District;
    }
    if (Citys) {
      fanalquery.City = Citys;
    }
    if (pincode) {
      fanalquery.pincode = pincode;
    }
    dispatch(allapiAction.lostfoundlistsearchpublic(fanalquery));
  };
  const hendletofilterCity = (e) => {
    setCity(e);
    setPage(1);

    var fanalquery = { page: page, City: e };
    if (category) {
      fanalquery.category = category;
    }
    if (search) {
      fanalquery.key = search;
    }
    if (state) {
      fanalquery.state = state;
    }
    if (District) {
      fanalquery.District = District;
    }
    // if (City) {
    //   fanalquery.City = City;
    // }
    if (pincode) {
      fanalquery.pincode = pincode;
    }
    dispatch(allapiAction.lostfoundlistsearchpublic(fanalquery));
  };
  const hendletofilterPin = (e) => {
    setpincode(e);
    setPage(1);

    var fanalquery = { page: page, pincode: e };
    if (category) {
      fanalquery.category = category;
    }
    if (search) {
      fanalquery.key = search;
    }
    if (state) {
      fanalquery.state = state;
    }
    if (District) {
      fanalquery.District = District;
    }
    if (Citys) {
      fanalquery.City = Citys;
    }
    // if (pincode) {
    //   fanalquery.pincode = pincode;
    // }
    dispatch(allapiAction.lostfoundlistsearchpublic(fanalquery));
  };

  const items = lost_and_found_pub?.totalDocs;
  const itemsPerPage = 12;
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setPageCount(Math.ceil(items && items / itemsPerPage));
  }, [itemOffset, itemsPerPage, lost_and_found_pub, items]);

  const handlePageClick = (event) => {
    const data = event?.selected + 1;
    const newOffset = (event.selected * itemsPerPage) % items;
    setItemOffset(newOffset);
    setPage(data);
    dispatch(
      allapiAction.messagelistpublic({
        page: data,
        category: "condolenceMessage",
      })
    );
  };

  const [windowWidth, setWindowWidth] = useState(600);

  // Update window width when the window is resized
  useLayoutEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div>
      <div className="ps-product-list ps-clothings mt-5 px-3">
        {/* <div className="ps-section__header my-5 border border-primary">
          <h3>All Message</h3>
        </div> */}
        <div className="ps-section__header my-5 border border-primary">
          <div className="col-md-3 col-sm-3 col-4">
            {" "}
            <h3>Shoksandesh</h3>
          </div>
          {/* <div className="col-md-3 col-sm-3 col-12">
            {" "}
            <AsyncPaginate
              value={stateselect}
              className="liceninput2"
              placeholder="State"
              loadOptions={loadOptionsState}
              onChange={setstateselect}
              additional={{
                page: 1,
              }}
            />
          </div>
          <div className="col-md-3 col-sm-3 col-12">
            {" "}
            <AsyncPaginate
              value={districtselect}
              className="liceninput2"
              placeholder="District"
              loadOptions={loadOptionsDisrcit}
              onChange={setdistrictselect}
              additional={{
                page: 1,
              }}
            />
          </div> */}
          <div className="col-md-3 col-sm-3 col-8">
            <AsyncPaginate
              value={cityselect}
              className="liceninput2"
              placeholder="Serach by your area"
              loadOptions={loadOptionsPalyer}
              onChange={setcityselect}
              additional={{
                page: 1,
              }}
            />
          </div>
        </div>
        <div className="ps-block__right"></div>
      </div>
      {/* </div> */}

      <div className="container-fluid shewBox">
        <div className="row">
          {lostfoundList &&
            lostfoundList?.map((data, i) => {
              return (
                <div
                  className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 mb-2"
                  key={i}
                >
                  <div
                    class={
                      data?.subcategoryId == "Homage"
                        ? "sokakul"
                        : data?.subcategoryId == "punyatithi"
                          ? "sokakul1"
                          : "sokakul2"
                    }
                  >
                    <div class="headernns">
                      {data?.subcategoryId == "Homage"
                        ? "शोक संदेश"
                        : data?.subcategoryId == "punyatithi"
                          ? data?.bethakno
                            ? data?.bethakno + " पुण्यतिथि"
                            : " " + " पुण्यतिथि"
                          : data?.bethakno
                            ? "  बैठक"
                            : " " + " की बैठक"}
                    </div>

                    {/* {data?.subcategoryId == "punyatithi" && (
                      <div class="sub-header">
                        हमारे प्रिय <b>{data?.name}</b> की प्रथम पुण्य तिथि पर
                        शांति पाठ एवं श्रद्धांजलि सभा का आयोजन किया गया है।
                      </div>
                    )}
                    {data?.subcategoryId == "Homage" && (
                      <div class="sub-header">
                        अत्यंत दुख के साथ सूचित किया जाता है
                        <br /> <b>{data?.name}</b> का स्वर्गवास हो गया है।
                      </div>
                    )} */}
                    {/* {data?.subcategoryId == "baithak" && (
                      <div class="sub-header">
                        <b>{data?.name}</b> की तेरहवीं की बैठक
                      </div>
                    )} */}
                    <div class="content">
                      <div
                        style={{
                          width: "70px",
                        }}
                          className="imgflot"
                      >
                        <img
                          src={URL.API_BASE_URL + data?.imagefor}
                          alt="श्रीमती सरस्वती देवी"
                          width="70"
                          // className="imgflot"
                        />
                        <b>{data?.name}</b>
                      </div>

                      <p>{data?.description}</p>
                    </div>
                    <hr />
                    <div class="footer">
                      <p>
                        <b> शोकाकुल परिवार:</b>{" "}
                        <TruncateText
                          text={data?.family}
                          maxLength={windowWidth > 600 ? 125 : 125}
                        />
                      </p>

                      {/* <p>
                  संतोष - आशी (पुत्री - दामाद), पूनम, कंचन, सोनू (पुत्री), आरव,
                  सानवी (पोते - पोती), गार्गी (दोहिती - दवादा)
                </p> */}
                    </div>

                    <div class="address">
                      <p>
                        <b> पता:</b> {data?.address}
                      </p>
                      {/* <p>मो. 9828175714, 9829193585</p> */}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <div className="container mt-20">
        <nav
          className="d-flex justify-content-between"
          aria-label="Page navigation"
        >
          <ReactPaginate
            breakLabel="..."
            nextLabel="Next"
            onPageChange={handlePageClick}
            className="paging_simple_numbersc pagination"
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="Previous"
            renderOnZeroPageCount={null}
          />
        </nav>
      </div>
      <div className="mt-25">
        <OurWork />
      </div>
    </div>
  );
}

export default Soksandhesh;
