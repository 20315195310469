// import { URL } from "@/Redux/common/url";
// import Link from "next/link";
import React, { useLayoutEffect, useState } from "react";
import Slider from "react-slick";
import CoustomImg from "../CoustomImg/CoustomImg";
import TruncateText from "../TruncateText/TruncateText";
import { URL } from "../../Redux/common/url";
import { Link } from "react-router-dom";

function Product({ homedata }) {
  const get_home_all_pro = homedata;
  const recentrecentProduct = get_home_all_pro?.LostFoundlist
    ? get_home_all_pro?.LostFoundlist
    : [];
  var settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 30000000,
    speed: 500,
    slidesToShow:
      get_home_all_pro?.LostFoundlist?.length == 1
        ? 1
        : get_home_all_pro?.LostFoundlist?.length > 8
          ? 8
          : get_home_all_pro?.LostFoundlist?.length - 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow:
            get_home_all_pro?.LostFoundlist?.length == 1
              ? 1
              : get_home_all_pro?.LostFoundlist?.length > 4
                ? 4
                : get_home_all_pro?.LostFoundlist?.length - 1,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow:
            get_home_all_pro?.LostFoundlist?.length == 1
              ? 1
              : get_home_all_pro?.LostFoundlist?.length > 3
                ? 3
                : get_home_all_pro?.LostFoundlist?.length - 1,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow:
            get_home_all_pro?.LostFoundlist?.length == 1
              ? 1
              : get_home_all_pro?.LostFoundlist?.length > 3
                ? 3
                : get_home_all_pro?.LostFoundlist?.length - 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const [windowWidth, setWindowWidth] = useState(600);

  // Update window width when the window is resized
  useLayoutEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      <div className="lostfound shewBox ps-deal-of-day">
        <div className="ps-container">
          <div className="ps-section__header">
            <div className="ps-block--countdown-deal">
              <div className="ps-block__left">
                <h3>
                  <b>RECENT LOST AND FOUND</b>{" "}
                </h3>
              </div>
            </div>
            <Link to="/all-lost-and-found">View all</Link>
          </div>
          <div className="ps-section__content">
            {recentrecentProduct?.length > 0 && (
              <Slider {...settings}>
                {recentrecentProduct &&
                  recentrecentProduct?.map((data, i) => {
                    return (
                      <div className="recentlostfound" key={i}>
                        <div className="ps-product ps-product--inner">
                          <div className="ps-product__thumbnail">
                            <div
                              className={
                                data?.subcategoryId == "found"
                                  ? "ps-product__badge hot"
                                  : "ps-product__badge"
                              }
                            >
                              {data?.subcategoryId}
                            </div>
                            <Link to={`/lost-and-found/${data?.slug}`}>
                              <CoustomImg
                                url={URL.API_BASE_URL + data?.image}
                                altkey={data?.title}
                                w1={165}
                                w2={185}
                                h1={80}
                                h2={130}
                              />
                            </Link>
                          </div>
                          <div className="ps-product__container">
                            <div className="ps-product__content">
                              <Link
                                className="ps-product__title text-center"
                                to={"/lost-and-found/" + data?.slug}
                              >
                                <TruncateText
                                  text={data?.name}
                                  maxLength={windowWidth > 600 ? 45 : 18}
                                />
                                {/* {data?.name} */}
                              </Link>

                              <div
                                className="ps-product__progress-bar "
                                data-value="82"
                              >
                                <p className="lostDate  text-center">
                                  {/* {data?.city} */}
                                  <TruncateText
                                    text={data?.district}
                                    maxLength={windowWidth > 600 ? 22 : 18}
                                  />
                                  {/* <br />
                                  10.15AM */}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </Slider>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Product;
