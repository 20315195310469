import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allapiAction } from "../../Redux/common/action";
import { URL } from "../../Redux/common/url";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function Weddinglisting() {
  // MerriageList
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [page, setpage] = useState(1);
  const [searchData, setserchData] = useState({});
  const { message, looking, agefrom, religion } = location.state || {};

  const hendleTochange = (e) => {
    setserchData({
      ...searchData,
      [e.target.name]: e.target.value,
    });
  };
  // const [ageto, setageto] = useState("")

  const get_marriage_list = useSelector((state) =>
    state?.allapi?.get_marriage_list ? state?.allapi?.get_marriage_list : {}
  );
  console.log(get_marriage_list);

  const docslist = get_marriage_list?.docs ? get_marriage_list?.docs : [];
  console.log(searchData);
  useEffect(() => {
    getList();

    return () => {};
  }, [location.state]);
  // useEffect(() => {
  //   setserchData(location.state);
  //   return () => {};
  // }, [location.state]);

  const getList = async (id) => {
    const serchData = { page: page };

    if (searchData?.agefrom && searchData?.ageto) {
      if (searchData?.agefrom > searchData?.ageto) {
        toast.error("age from should be less than age to");
      }
    }

    if (searchData?.agefrom) {
      serchData.agefrom = searchData?.agefrom;
    }
    if (searchData?.ageto) {
      serchData.ageto = searchData?.ageto;
    }
    if (searchData?.annualIncome) {
      serchData.annualIncome = searchData?.annualIncome;
    }
    if (searchData?.gander) {
      serchData.gander = searchData?.gander;
    }
    if (searchData?.caste) {
      serchData.caste = searchData?.caste;
    }
    if (searchData?.maritalStatus) {
      serchData.maritalStatus = searchData?.maritalStatus;
    }
    if (searchData?.religion) {
      serchData.religion = searchData?.religion;
    }

    ["gander", "religion", "agefrom"].forEach((key) => {
      if (!searchData[key] && location.state?.[key]) {
        setserchData((prevData) => ({
          ...prevData,
          [key]: location.state[key],
        }));
        serchData[key] = location.state[key];
      }
    });
    // if (searchData?.minHeight) {
    //   serchData.minHeight = searchData?.minHeight;
    // }
    // if (searchData?.maxHeight) {
    //   serchData.maxHeight = searchData?.maxHeight;
    // }

    await dispatch(allapiAction?.MerriageList(serchData));
  };

  return (
    <div class="weddinglisting">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 hhh text-center">
            <h1 class="mt-5 pt-5">Active Members</h1>
          </div>
        </div>
      </div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>

      <div class="container mt-4">
        <div class="row">
          <div class="col-md-3">
            <div class="border shadow-lg p-10 mb-5 bg-white rounded">
              <p class="mt-2 m-0 p-0">Age From</p>
              <input
                type="number"
                class="form-control"
                placeholder="Age From"
                name="agefrom"
                value={searchData?.agefrom}
                onChange={(e) => hendleTochange(e)}
              />
              <p class="mt-2 m-0 p-0">To</p>
              <input
                type="number"
                class="form-control"
                placeholder="Age To"
                name="ageto"
                value={searchData?.ageto}
                onChange={(e) => hendleTochange(e)}
              />
              {/* <p class="mt-2 m-0 p-0">Member ID</p>
              <input type="text" class="form-control" placeholder="Member ID" /> */}
              <p class="mt-2 p-0 m-0">Income</p>
              <select
                class="form-control"
                name="annualIncome"
                value={searchData?.annualIncome}
                onChange={(e) => hendleTochange(e)}
              >
                <option value="">Select Income</option>
                <option value="1">Up To INR 1 Lakh</option>
                <option value="2">INR 1 Lakh To Less Than 2 Lakh</option>
                <option value="4">INR 2 Lakh To Less Than 4 Lakh</option>
                <option value="7">INR 4 Lakh To Less Than 7 Lakh</option>
                <option value="10">INR 7 Lakh To Less Than 10 Lakh</option>
                <option value="15">INR 10 Lakh To 15 Lakh</option>
                <option value="20">INR 15 Lakh To Less Than 20 Lakh</option>
                <option value="30">INR 20 Lakh To Less Than 30 Lakh</option>
                <option value="50">INR 30 Lakh To Less Than 50 Lakh</option>
                <option value="75">INR 50 Lakh To Less Than 75 Lakh</option>
                <option value="100">INR 75 Lakh To Less Than 1 Crore</option>
                <option value="101">INR 1 Crore To Above</option>
                <option value="0">Do Not Show</option>
              </select>
              <p class="mt-2 p-0 m-0">Gender</p>
              <select
                class="form-control"
                name="gander"
                value={searchData?.gander}
                onChange={(e) => hendleTochange(e)}
              >
                <option value="">Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
              <p class="mt-2 p-0 m-0">Marital Status</p>
              <select
                class="form-control"
                name="maritalStatus"
                value={searchData?.maritalStatus}
                onChange={(e) => hendleTochange(e)}
              >
                <option value="">Select Marital Status</option>
                <option value="never-married">Never Married</option>
                <option value="widow">Widow</option>
                <option value="widower">Widower</option>
                <option value="divorce">Divorce</option>
                <option value="unfertile">Unfertile</option>
              </select>

              {/* <p class="mt-2 p-0 m-0">Select Religion</p>
              <select class="form-control">
                <option value="">Select Religion</option>
                <option value="religion1">Religion1</option>
                <option value="religion2">Religion2</option>
              </select> */}
              <p class="mt-2 m-0 p-0">Select Religion</p>
              {/* <input
                type="text"
                class="form-control"
                placeholder="religion"
                name="religion"
                onChange={(e) => hendleTochange(e)}
              /> */}
              <select
                class="form-control"
                name="religion"
                value={searchData?.religion}
                onChange={(e) => hendleTochange(e)}
              >
                <option value="">Select Religion</option>
                <option value="Hindu">Hindu</option>
                <option value="Islam">Islam</option>
                <option value="Christianity">Christianity</option>
                <option value="Sikhism">Sikhism</option>
                <option value="Buddhism">Buddhism</option>
                <option value="Jainism">Jainism</option>
                <option value="Zoroastrianism">Zoroastrianism</option>
                <option value="Judaism">Judaism</option>
                <option value="Sanamahism">Sanamahism</option>
                <option value="Animism">Animism</option>
                <option value="Sarnaism">Sarnaism</option>
              </select>
              <p class="mt-2 p-0 m-0">Select Caste</p>
              <select
                class="form-control"
                name="caste"
                value={searchData?.caste}
                onChange={(e) => hendleTochange(e)}
              >
                <option value="">Select Caste</option>
                <option value="Vaish">Vaish</option>
                <option value="Brahmin">Brahmin</option>
                <option value="PUNJABI">PUNJABI</option>
                <option value="Jatav">Jatav</option>
                <option value="Please Select">Please Select</option>
                <option value="Agaria - Hindu">Agaria - Hindu</option>
                <option value="Arora ">Arora </option>
                <option value="Arora - Sikh">Arora - Sikh</option>
                <option value="Badhai - Hindu">Badhai - Hindu</option>
                <option value="Badhi - Hindu">Badhi - Hindu</option>
                <option value="Balmiki">Balmiki</option>
                <option value="Bangali - Hindu">Bangali - Hindu</option>
                <option value="Banjara - Hindu">Banjara - Hindu</option>
                <option value="Banjara - Hindu (Nayak)">
                  Banjara - Hindu (Nayak)
                </option>
                <option value="Bari - Hindu">Bari - Hindu</option>
                <option value="Barman">Barman</option>
                <option value="Bhoi - Hindu">Bhoi - Hindu</option>
                <option value="Brahma Kshatriya">Brahma Kshatriya</option>
                <option value="Brahmin - Dhiman">Brahmin - Dhiman</option>
                <option value="Brahmin - Kanaujia">Brahmin - Kanaujia</option>
                <option value="Brahmin - Sanadhya">Brahmin - Sanadhya</option>
                <option value="Chamar">Chamar</option>
                <option value="Chamar | Ravidasia">Chamar | Ravidasia</option>
                <option value="Chhipi">Chhipi</option>
                <option value="Christian unspecified">
                  Christian unspecified
                </option>
                <option value="Dhanuk">Dhanuk</option>
                <option value="Dheevar">Dheevar</option>
                <option value="Dhimar">Dhimar</option>
                <option value="Dhobi">Dhobi</option>
                <option value="Dhobi - Hindu">Dhobi - Hindu</option>
                <option value="Gadaria">Gadaria</option>
                <option value="Gadaria - Hindu">Gadaria - Hindu</option>
                <option value="Gadariya">Gadariya</option>
                <option value="Gangwar">Gangwar</option>
                <option value="Gond">Gond</option>
                <option value="Gosain - Hindu">Gosain - Hindu</option>
                <option value="Goswami Brahmin">Goswami Brahmin</option>
                <option value="Gounder">Gounder</option>
                <option value="Gowari">Gowari</option>
                <option value="Guria">Guria</option>
                <option value="Gurjar">Gurjar</option>
                <option value="HP">HP</option>
                <option value="JAAT">JAAT</option>
                <option value="Jain">Jain</option>
                <option value="Jaiswar">Jaiswar</option>
                <option value="Jogi - Hindu">Jogi - Hindu</option>
                <option value="Julaha">Julaha</option>
                <option value="Julaha Hindu">Julaha Hindu</option>
                <option value="Jyotish Brahmin">Jyotish Brahmin</option>
                <option value="Jyotishi Brahmin">Jyotishi Brahmin</option>
                <option value="Kamboj ">Kamboj </option>
                <option value="Kapu">Kapu</option>
                <option value="Kashmiri Sikh">Kashmiri Sikh</option>
                <option value="Kashyap">Kashyap</option>
                <option value="KAYASTH">KAYASTH</option>
                <option value="Kayastha">Kayastha</option>
                <option value="Khateek">Khateek</option>
                <option value="Khatik - Hindu">Khatik - Hindu</option>
                <option value="Khatri">Khatri</option>
                <option value="Khatri - Sikh">Khatri - Sikh</option>
                <option value="Khatris">Khatris</option>
                <option value="Koli">Koli</option>
                <option value="Koli Mahadev">Koli Mahadev</option>
                <option value="Kori">Kori</option>
                <option value="Kori (S.C.)">Kori (S.C.)</option>
                <option value="Kshatriya">Kshatriya</option>
                <option value="Kshatriya-Thakur">Kshatriya-Thakur</option>
                <option value="Kumauni Brahmin">Kumauni Brahmin</option>
                <option value="Kumauni Rajput">Kumauni Rajput</option>
                <option value="Kumhar">Kumhar</option>
                <option value="Kumhar | Prajapati">Kumhar | Prajapati</option>
                <option value="Kunbi">Kunbi</option>
                <option value="Kurmi">Kurmi</option>
                <option value="Kushwaha">Kushwaha</option>
                <option value="Lodhi Rajput">Lodhi Rajput</option>
                <option value="Lohar">Lohar</option>
                <option value="Mali">Mali</option>
                <option value="Maurya">Maurya</option>
                <option value="Mudaliar">Mudaliar</option>
                <option value="Musahar - Hindu">Musahar - Hindu</option>
                <option value="MUSLIM">MUSLIM</option>
                <option value="Nai">Nai</option>
                <option value="Nair">Nair</option>
                <option value="Nishad">Nishad</option>
                <option value="Other">Other</option>
                <option value="Pal">Pal</option>
                <option value="Parekh">Parekh</option>
                <option value="Pasi (Schedule)">Pasi (Schedule)</option>
                <option value="Pasi - Hindu">Pasi - Hindu</option>
                <option value="Prajapati">Prajapati</option>
                <option value="Prajapati (Kumhar)">Prajapati (Kumhar)</option>
                <option value="Punjabi Brahmin">Punjabi Brahmin</option>
                <option value="Rajpur">Rajpur</option>
                <option value="Rajput">Rajput</option>
                <option value="Rajput - Bais - Hindu">
                  Rajput - Bais - Hindu
                </option>
                <option value="Rajput - Bhatti - Hindu">
                  Rajput - Bhatti - Hindu
                </option>
                <option value="Ramgarhia">Ramgarhia</option>
                <option value="Rawat">Rawat</option>
                <option value="Sahu">Sahu</option>
                <option value="Saini">Saini</option>
                <option value="Sarera - Sikh">Sarera - Sikh</option>
                <option value="Sauria Paharia">Sauria Paharia</option>
                <option value="Select Caste">Select Caste</option>
                <option value="Shah">Shah</option>
                <option value="Shakya ">Shakya </option>
                <option value="Sikh">Sikh</option>
                <option value="Sikh - Jatt">Sikh - Jatt</option>
                <option value="Sikh Arora / Khatri">Sikh Arora / Khatri</option>
                <option value="Sikh Jat">Sikh Jat</option>
                <option value="Silpkar - Hindu">Silpkar - Hindu</option>
                <option value="Sindhi">Sindhi</option>
                <option value="Sindhi Vaish">Sindhi Vaish</option>
                <option value="Somvanshi Rajput">Somvanshi Rajput</option>
                <option value="Sonar">Sonar</option>
                <option value="Sonar (Marathi Goldsmith Caste)">
                  Sonar (Marathi Goldsmith Caste)
                </option>
                <option value="Sonar - Muslim">Sonar - Muslim</option>
                <option value="Sonar - Rastogi">Sonar - Rastogi</option>
                <option value="Sonker">Sonker</option>
                <option value="Sonr">Sonr</option>
                <option value="Tamboli - Hindu">Tamboli - Hindu</option>
                <option value="Tarkhan">Tarkhan</option>
                <option value="Teli">Teli</option>
                <option value="Tyagi">Tyagi</option>
                <option value="Ujia">Ujia</option>
                <option value="UP Khatri">UP Khatri</option>
                <option value="Vaish - Gahoi">Vaish - Gahoi</option>
                <option value="Valmiki">Valmiki</option>
                <option value="Viswakarma">Viswakarma</option>
                <option value="Yadav">Yadav</option>
              </select>
              {/* <p class="mt-2 p-0 m-0">Mother Tongue</p>
              <select class="form-control">
                <option value="">Mother Tongue</option>
                <option value="tongue1">Tongue1</option>
                <option value="tongue2">Tongue2</option>
              </select> */}
              {/* <p class="mt-2 p-0 m-0">Select Country</p> */}
              {/* <select class="form-control">
                <option value="">Select Country</option>
                <option value="country1">Country1</option>
                <option value="country2">Country2</option>
              </select>
              <p class="mt-2 p-0 m-0">Select City</p>
              <select class="form-control">
                <option value="">Select City</option>
                <option value="city1">City1</option>
                <option value="city2">City2</option>
              </select> */}
              {/* <p class="mt-2 m-0 p-0">Max Height (In Feet)</p>
               
                 <select
                id="height"
                name="minHeight"
                // value={lostfinddata?.height}
                // onChange={(e) => {
                //   hendletochange(e);
                // }}

                onChange={(e) => hendleTochange(e)} 
                required
              >
                <option value="">Please Select</option>
                <option value="3 feet 5 inches">3 feet 5 inches</option>
                <option value="3 feet 6 inches">3 feet 6 inches</option>
                <option value="3 feet 7 inches">3 feet 7 inches</option>
                <option value="3 feet 8 inches">3 feet 8 inches</option>
                <option value="3 feet 9 inches">3 feet 9 inches</option>
                <option value="3 feet 10 inches">3 feet 10 inches</option>
                <option value="3 feet 11 inches">3 feet 11 inches</option>
                <option value="4 feet">4 feet</option>
                <option value="4 feet 1 inch">4 feet 1 inch</option>
                <option value="4 feet 2 inches">4 feet 2 inches</option>
                <option value="4 feet 3 inches">4 feet 3 inches</option>
                <option value="4 feet 4 inches">4 feet 4 inches</option>
                <option value="4 feet 5 inches">4 feet 5 inches</option>
                <option value="4 feet 6 inches">4 feet 6 inches</option>
                <option value="4 feet 7 inches">4 feet 7 inches</option>
                <option value="4 feet 8 inches">4 feet 8 inches</option>
                <option value="4 feet 9 inches">4 feet 9 inches</option>
                <option value="4 feet 10 inches">4 feet 10 inches</option>
                <option value="4 feet 11 inches">4 feet 11 inches</option>
                <option value="5 feet">5 feet</option>
                <option value="5 feet 1 inch">5 feet 1 inch</option>
                <option value="5 feet 2 inches">5 feet 2 inches</option>
                <option value="5 feet 3 inches">5 feet 3 inches</option>
                <option value="5 feet 4 inches">5 feet 4 inches</option>
                <option value="5 feet 5 inches">5 feet 5 inches</option>
                <option value="5 feet 6 inches">5 feet 6 inches</option>
                <option value="5 feet 7 inches">5 feet 7 inches</option>
                <option value="5 feet 8 inches">5 feet 8 inches</option>
                <option value="5 feet 9 inches">5 feet 9 inches</option>
                <option value="5 feet 10 inches">5 feet 10 inches</option>
                <option value="5 feet 11 inches">5 feet 11 inches</option>
                <option value="6 feet">6 feet</option>
                <option value="6 feet 1 inch">6 feet 1 inch</option>
                <option value="6 feet 2 inches">6 feet 2 inches</option>
                <option value="6 feet 3 inches">6 feet 3 inches</option>
                <option value="6 feet 4 inches">6 feet 4 inches</option>
                <option value="6 feet 5 inches">6 feet 5 inches</option>
                <option value="6 feet 6 inches">6 feet 6 inches</option>
                <option value="6 feet 7 inches">6 feet 7 inches</option>
                <option value="6 feet 8 inches">6 feet 8 inches</option>
                <option value="6 feet 9 inches">6 feet 9 inches</option>
                <option value="6 feet 10 inches">6 feet 10 inches</option>
                <option value="6 feet 11 inches">6 feet 11 inches</option>
                <option value="7 feet">7 feet</option>
                <option value="7 feet 1 inch">7 feet 1 inch</option>
                <option value="7 feet 2 inches">7 feet 2 inches</option>
                <option value="7 feet 3 inches">7 feet 3 inches</option>
                <option value="7 feet 4 inches">7 feet 4 inches</option>
                <option value="7 feet 5 inches">7 feet 5 inches</option>
                <option value="7 feet 6 inches">7 feet 6 inches</option>
                <option value="7 feet 7 inches">7 feet 7 inches</option>
                <option value="7 feet 8 inches">7 feet 8 inches</option>
                <option value="7 feet 9 inches">7 feet 9 inches</option>
                <option value="7 feet 10 inches">7 feet 10 inches</option>
                <option value="7 feet 11 inches">7 feet 11 inches</option>
                <option value="8 feet">8 feet</option>
              </select>
              <p class="mt-2 m-0 p-0">Min Height (In Feet)</p>
            

              <select
                id="height"
                name="maxHeight"
                // value={lostfinddata?.height}
                // onChange={(e) => {
                //   hendletochange(e);
                // }}

                onChange={(e) => hendleTochange(e)} 
                required
              >
                <option value="">Please Select</option>
                <option value="3 feet 5 inches">3 feet 5 inches</option>
                <option value="3 feet 6 inches">3 feet 6 inches</option>
                <option value="3 feet 7 inches">3 feet 7 inches</option>
                <option value="3 feet 8 inches">3 feet 8 inches</option>
                <option value="3 feet 9 inches">3 feet 9 inches</option>
                <option value="3 feet 10 inches">3 feet 10 inches</option>
                <option value="3 feet 11 inches">3 feet 11 inches</option>
                <option value="4 feet">4 feet</option>
                <option value="4 feet 1 inch">4 feet 1 inch</option>
                <option value="4 feet 2 inches">4 feet 2 inches</option>
                <option value="4 feet 3 inches">4 feet 3 inches</option>
                <option value="4 feet 4 inches">4 feet 4 inches</option>
                <option value="4 feet 5 inches">4 feet 5 inches</option>
                <option value="4 feet 6 inches">4 feet 6 inches</option>
                <option value="4 feet 7 inches">4 feet 7 inches</option>
                <option value="4 feet 8 inches">4 feet 8 inches</option>
                <option value="4 feet 9 inches">4 feet 9 inches</option>
                <option value="4 feet 10 inches">4 feet 10 inches</option>
                <option value="4 feet 11 inches">4 feet 11 inches</option>
                <option value="5 feet">5 feet</option>
                <option value="5 feet 1 inch">5 feet 1 inch</option>
                <option value="5 feet 2 inches">5 feet 2 inches</option>
                <option value="5 feet 3 inches">5 feet 3 inches</option>
                <option value="5 feet 4 inches">5 feet 4 inches</option>
                <option value="5 feet 5 inches">5 feet 5 inches</option>
                <option value="5 feet 6 inches">5 feet 6 inches</option>
                <option value="5 feet 7 inches">5 feet 7 inches</option>
                <option value="5 feet 8 inches">5 feet 8 inches</option>
                <option value="5 feet 9 inches">5 feet 9 inches</option>
                <option value="5 feet 10 inches">5 feet 10 inches</option>
                <option value="5 feet 11 inches">5 feet 11 inches</option>
                <option value="6 feet">6 feet</option>
                <option value="6 feet 1 inch">6 feet 1 inch</option>
                <option value="6 feet 2 inches">6 feet 2 inches</option>
                <option value="6 feet 3 inches">6 feet 3 inches</option>
                <option value="6 feet 4 inches">6 feet 4 inches</option>
                <option value="6 feet 5 inches">6 feet 5 inches</option>
                <option value="6 feet 6 inches">6 feet 6 inches</option>
                <option value="6 feet 7 inches">6 feet 7 inches</option>
                <option value="6 feet 8 inches">6 feet 8 inches</option>
                <option value="6 feet 9 inches">6 feet 9 inches</option>
                <option value="6 feet 10 inches">6 feet 10 inches</option>
                <option value="6 feet 11 inches">6 feet 11 inches</option>
                <option value="7 feet">7 feet</option>
                <option value="7 feet 1 inch">7 feet 1 inch</option>
                <option value="7 feet 2 inches">7 feet 2 inches</option>
                <option value="7 feet 3 inches">7 feet 3 inches</option>
                <option value="7 feet 4 inches">7 feet 4 inches</option>
                <option value="7 feet 5 inches">7 feet 5 inches</option>
                <option value="7 feet 6 inches">7 feet 6 inches</option>
                <option value="7 feet 7 inches">7 feet 7 inches</option>
                <option value="7 feet 8 inches">7 feet 8 inches</option>
                <option value="7 feet 9 inches">7 feet 9 inches</option>
                <option value="7 feet 10 inches">7 feet 10 inches</option>
                <option value="7 feet 11 inches">7 feet 11 inches</option>
                <option value="8 feet">8 feet</option>
              </select> */}

              <button
                type="button"
                onClick={() => getList()}
                class="btn btn-info btn-lg btn-block mt-3 btn-custom"
              >
                Search
              </button>
            </div>
          </div>

          <div class="col-md-9">
            {docslist &&
              docslist?.map((data, i) => {
                let dob = new Date(data?.dob);
                let today = new Date();
                let age = Math.floor(
                  (today - dob) / (365.25 * 24 * 60 * 60 * 1000)
                );
                return (
                  <div class="border shadow-lg p-3 mb-5 bg-white rounded">
                    <div class="row">
                      <div class="col-sm-3 text-center">
                        <img
                          onClick={() => {
                            navigate("/wedding/profile/" + data?.slug);
                          }}
                          src={URL?.API_BASE_URL + data?.image}
                          class="profile-img img-fluid"
                        />
                      </div>

                      <div class="col-sm-9">
                        <div class="row">
                          <div class="col-md-7">
                            <h5>{data?.firstName + " " + data?.lastName}</h5>
                            {/* <p>
                              Member ID:{" "}
                              <span style={{ color: "red" }}>AM16635935</span>
                            </p> */}
                            <p class="text-muted m-0 p-0 mt-2">
                              Age: {age} Years
                            </p>
                            <p class="text-muted m-0 p-0">
                              Religion: {data?.religion}
                            </p>
                            <p class="text-muted m-0 p-0">
                              Location: {data?.city}
                            </p>
                          </div>

                          <div class="col-md-5 text-right">
                            <button
                              type="button"
                              class="btn btn-secondary btn-sm btn-custom"
                              disabled
                            >
                              Premium
                            </button>
                            <br />
                            <br />
                            <p class="text-muted m-0 p-0">
                              Height: {data?.height} Feet
                            </p>
                            <p class="text-muted m-0 p-0">
                              Caste: {data?.caste}
                            </p>
                            <p class="text-muted m-0 p-0">
                              Marital Status:{data?.maritalStatus}
                            </p>
                          </div>
                        </div>

                        <div class="row mt-4">
                          <div class="col-12">
                            <p>
                              <span
                                onClick={() => {
                                  navigate("/wedding/profile/" + data?.slug);
                                }}
                              >
                                Profile
                              </span>{" "}
                              | Make Interest | Shortlist | Ignore | Report |
                              Message
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            {/* <div class="border shadow-lg p-3 mb-5 bg-white rounded">
              <div class="row">
                <div class="col-sm-3 text-center">
                  <img
                    src="https://cdn4.sharechat.com/weddinggirlphoto_2bf4fdca_1590761190317_cmprsd_40.jpg?tenant=sc&referrer=tag-service&f=rsd_40.jpg"
                    class="profile-img img-fluid"
                  />
                </div>

                <div class="col-sm-9">
                  <div class="row">
                    <div class="col-md-7">
                      <h5>Amelia Emma</h5>
                      <p>
                        Member ID:{" "}
                        <span style={{ color: "red" }}>AM16635935</span>
                      </p>
                      <p class="text-muted m-0 p-0 mt-2">Age: 27 Years</p>
                      <p class="text-muted m-0 p-0">Religion: Muslim</p>
                      <p class="text-muted m-0 p-0">Location: Swaziland</p>
                    </div>

                    <div class="col-md-5 text-right">
                      <button
                        type="button"
                        class="btn btn-secondary btn-sm btn-custom"
                        disabled
                      >
                        Premium
                      </button>
                      <br />
                      <br />
                      <p class="text-muted m-0 p-0">Height: 6 Feet</p>
                      <p class="text-muted m-0 p-0">Caste: Sunni</p>
                      <p class="text-muted m-0 p-0">
                        Marital Status: Never Married
                      </p>
                    </div>
                  </div>

                  <div class="row mt-4">
                    <div class="col-12">
                      <p>
                        Profile | Make Interest | Shortlist | Ignore | Report |
                        Message
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="border shadow-lg p-3 mb-5 bg-white rounded">
              <div class="row">
                <div class="col-sm-3 text-center">
                  <img
                    src="https://cdn4.sharechat.com/weddinggirlphoto_2bf4fdca_1590761190317_cmprsd_40.jpg?tenant=sc&referrer=tag-service&f=rsd_40.jpg"
                    class="profile-img img-fluid"
                  />
                </div>

                <div class="col-sm-9">
                  <div class="row">
                    <div class="col-md-7">
                      <h5>Amelia Emma</h5>
                      <p>
                        Member ID:{" "}
                        <span style={{ color: "red" }}>AM16635935</span>
                      </p>
                      <p class="text-muted m-0 p-0 mt-2">Age: 27 Years</p>
                      <p class="text-muted m-0 p-0">Religion: Muslim</p>
                      <p class="text-muted m-0 p-0">Location: Swaziland</p>
                    </div>

                    <div class="col-md-5 text-right">
                      <button
                        type="button"
                        class="btn btn-secondary btn-sm btn-custom"
                        disabled
                      >
                        Premium
                      </button>
                      <br />
                      <br />
                      <p class="text-muted m-0 p-0">Height: 6 Feet</p>
                      <p class="text-muted m-0 p-0">Caste: Sunni</p>
                      <p class="text-muted m-0 p-0">
                        Marital Status: Never Married
                      </p>
                    </div>
                  </div>

                  <div class="row mt-4">
                    <div class="col-12">
                      <p>
                        Profile | Make Interest | Shortlist | Ignore | Report |
                        Message
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="border shadow-lg p-3 mb-5 bg-white rounded">
              <div class="row">
                <div class="col-sm-3 text-center">
                  <img
                    src="https://cdn4.sharechat.com/weddinggirlphoto_2bf4fdca_1590761190317_cmprsd_40.jpg?tenant=sc&referrer=tag-service&f=rsd_40.jpg"
                    class="profile-img img-fluid"
                  />
                </div>

                <div class="col-sm-9">
                  <div class="row">
                    <div class="col-md-7">
                      <h5>Amelia Emma</h5>
                      <p>
                        Member ID:{" "}
                        <span style={{ color: "red" }}>AM16635935</span>
                      </p>
                      <p class="text-muted m-0 p-0 mt-2">Age: 27 Years</p>
                      <p class="text-muted m-0 p-0">Religion: Muslim</p>
                      <p class="text-muted m-0 p-0">Location: Swaziland</p>
                    </div>

                    <div class="col-md-5 text-right">
                      <button
                        type="button"
                        class="btn btn-secondary btn-sm btn-custom"
                        disabled
                      >
                        Premium
                      </button>
                      <br />
                      <br />
                      <p class="text-muted m-0 p-0">Height: 6 Feet</p>
                      <p class="text-muted m-0 p-0">Caste: Sunni</p>
                      <p class="text-muted m-0 p-0">
                        Marital Status: Never Married
                      </p>
                    </div>
                  </div>

                  <div class="row mt-4">
                    <div class="col-12">
                      <p>
                        Profile | Make Interest | Shortlist | Ignore | Report |
                        Message
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Weddinglisting;
