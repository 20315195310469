import React, { useEffect, useState } from "react";
import ImageCropper from "../ImageCroper/ImageCropper";
import { URL } from "../../Redux/common/url";
import { toast } from "react-toastify";
import Api from "../../Redux/common/api";
import { useNavigate } from "react-router-dom";
import { AsyncPaginate } from "react-select-async-paginate";
import { useDispatch } from "react-redux";
import { allapiAction } from "../../Redux/common/action";

function WeddingForm() {
  const [profileData, setprofileData] = useState({});

  console.log(profileData);
  const [fileName, setFileName] = useState("");
  const [fileContant, setfileContant] = useState("");
  const [finalImg, setfinalImg] = useState("");
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);

  const dispatch = useDispatch();
  useEffect(() => {
    // getmarriageProfile
    getProfileData();
    return () => {};
  }, []);

  const [lostfinddata, setlostfinddata] = useState({
    subCate: "",
    subsubCate: "",
    country: "India",
  });
  console.log(lostfinddata);

  const getProfileData = async () => {
    const response = await dispatch(allapiAction.getmarriageProfile({}));
    setprofileData(response?.data);
    setlostfinddata(response?.data);
    setCroppedImageUrl(URL.API_BASE_URL + response?.data?.image);
    //  setCroppedImageUrl
  };

  const navigate = useNavigate();
  const hendletochange = (e) => {
    const { name, value } = e?.target;

    if (name === "ageRange") {
      const newAgeRange = value.split(",").map(Number); // Convert to array of numbers

      setlostfinddata({ ...lostfinddata, [name]: newAgeRange });
    } else {
      setlostfinddata({ ...lostfinddata, [name]: value });
    }
  };

  console.log(fileName);
  console.log(fileContant);

  console.log(croppedImageUrl);
  const [stateselect, setstateselect] = useState(null);
  const [districtselect, setdistrictselect] = useState(null);
  const [cityselect, setcityselect] = useState(null);

  const [mangeKey, setmangeKey] = useState(0);
  const [mangeKey2, setmangeKey2] = useState(0);

  useEffect(() => {
    setmangeKey(mangeKey + 1);
    setmangeKey2(mangeKey2 + 1);
    setdistrictselect(null);
    setcityselect(null);

    return () => {};
  }, [stateselect]);
  useEffect(() => {
    // setmangeKey(mangeKey + 1);
    setmangeKey2(mangeKey2 + 1);
    setcityselect(null);
    return () => {};
  }, [districtselect]);

  const loadOptionsState = async (search, loadedOptions, pages) => {
    console.log(loadedOptions);
    console.log(pages);
    const response = await fetch(
      URL.API_BASE_URL +
        `/publicApi/searchareaStates?key=${search}&page=${pages?.page}`
    );
    const responseJSON = await response.json();

    const options = responseJSON?.results?.map((data) => {
      data.value = data?._id;
      data.label = data?.State;

      return data;
    });

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: pages?.page + 1,
      },
    };
  };
  const loadOptionsDisrcit = async (search, loadedOptions, pages) => {
    console.log(loadedOptions);
    console.log(pages);
    const response = await fetch(
      URL.API_BASE_URL +
        `/publicApi/searchareaDistricts?key=${search}&page=${pages?.page}&States_id=${stateselect?.State}`
    );
    const responseJSON = await response.json();

    const options = responseJSON?.results?.map((data) => {
      data.value = data?._id;
      data.label = data?.District;

      return data;
    });

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: pages?.page + 1,
      },
    };
  };
  const loadOptionsCity = async (search, loadedOptions, pages) => {
    console.log(loadedOptions);
    console.log(pages);
    const response = await fetch(
      URL.API_BASE_URL +
        `/publicApi/searchareacity?key=${search}&page=${pages?.page}&District_id=${districtselect?.District}`
    );
    const responseJSON = await response.json();

    const options = responseJSON?.results?.map((data) => {
      data.value = data?._id;
      data.label = data?.Area + ", " + data?.Pincode;

      return data;
    });

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: pages?.page + 1,
      },
    };
  };
  const validateAgeRange = (ageArray) => {
    // const ageArray = ageRange.split(",").map(Number);
    console.log(ageArray);

    if (ageArray.length !== 2 || isNaN(ageArray[0]) || isNaN(ageArray[1])) {
      // setError("Age range must be in the format 'min,max' (e.g., 25,30)");
      toast.error("Age range must be in the format 'min,max' (e.g., 25,30)");
      return false;
    }

    const [min, max] = ageArray;
    if (min >= max || min <= 0 || max <= 0) {
      toast.error(
        "Invalid age range. Ensure minimum is less than maximum and both are positive."
      );
      // setError("Invalid age range. Ensure minimum is less than maximum and both are positive.");
      return false;
    }

    // setError(""); // Clear any existing errors
    return true;
  };

  const hendleTosubmit = async () => {
    const data = new FormData();
    try {
      if (!croppedImageUrl) {
        toast.error("please select Image");
      } else if (!lostfinddata?.firstName) {
        toast.error("please enter first name");
      } else if (!lostfinddata?.lastName) {
        toast.error("please enter last name");
      } else if (!lostfinddata?.dob) {
        toast.error("please select dob");
      } /* else if (images2?.length == 0) {
        toast.error("please select your image ");
      } */ else if (!stateselect?.State) {
        toast.error("please select state ");
      } else if (!districtselect?.District) {
        toast.error("please select district ");
      } else if (!cityselect?.Area) {
        toast.error("please select city ");
      } else {
        if (!lostfinddata?.ageRange) {
          toast.error("please enter age range");
          return;
        }
        const { ageRange } = lostfinddata;
        if (!validateAgeRange(ageRange)) {
          return; // Stop submission if validation fails
        }

        data.append("firstName", lostfinddata?.firstName);
        data.append("lastName", lostfinddata?.lastName);
        data.append("maritalStatus", lostfinddata?.maritalStatus);
        data.append("religion", lostfinddata?.religion);
        data.append("manglik", lostfinddata?.manglik);
        data.append("caste", lostfinddata?.caste);
        data.append("subCaste", lostfinddata?.subCaste);
        data.append("gothra", lostfinddata?.gothra);
        data.append("ppmaritalStatus", lostfinddata?.ppmaritalStatus);
        data.append("ageRange", lostfinddata?.ageRange);
        data.append("ppfeaturesBodyType", lostfinddata?.ppfeaturesBodyType);
        data.append("ppcomplexion", lostfinddata?.ppcomplexion);
        data.append("ppsmoke", lostfinddata?.ppsmoke);
        data.append("ppyourEatingHabits", lostfinddata?.ppyourEatingHabits);
        data.append("pptakeHardDrinks", lostfinddata?.pptakeHardDrinks);
        // data.append("country", lostfinddata?.country);
        // data.append("state", stateselect?.State);
        // data.append("district", districtselect?.District);
        // data.append("city", cityselect?.Area);
        data.append("address", lostfinddata?.address);
        data.append("address2", lostfinddata?.address2);
        data.append("education", lostfinddata?.education);
        data.append("educationalArea", lostfinddata?.educationalArea);
        data.append("occupationType", lostfinddata?.occupationType);
        data.append("occupationCategory", lostfinddata?.occupationCategory);
        data.append("occupation", lostfinddata?.occupation);

        data.append("annualIncome", lostfinddata?.annualIncome);

        data.append("placeOfBirth", lostfinddata?.placeOfBirth);
        data.append("timeofbirths", lostfinddata?.timeofbirths);
        data.append("nakshatra", lostfinddata?.nakshatra);
        data.append("featuresBodyType", lostfinddata?.featuresBodyType);
        data.append("complexion", lostfinddata?.complexion);
        data.append("builtStructure", lostfinddata?.builtStructure);
        data.append("specialCases", lostfinddata?.specialCases);
        data.append("bloodGroup", lostfinddata?.bloodGroup);
        data.append("gander", lostfinddata?.gander);
        data.append("dob", lostfinddata?.dob);
        data.append("height", lostfinddata?.height);
        data.append("Raasi", lostfinddata?.Raasi);
        data.append("bodyWeight", lostfinddata?.bodyWeight);
        data.append("smoke", lostfinddata?.smoke);
        data.append("yourEatingHabits", lostfinddata?.yourEatingHabits);
        data.append("takeHardDrinks", lostfinddata?.takeHardDrinks);
        // data.append("name", lostfinddata?.name);

        // data.append("categoryId", lostfinddata?.subCate);
        // data.append("subcategoryId", lostfinddata?.subsubCate);
        // data.append("bethakno", lostfinddata?.bethakno);

        // data.append("gender", lostfinddata?.gender);
        // data.append("address", lostfinddata?.address);
        // data.append("family", lostfinddata?.family);
        // data.append("gender", lostfinddata?.gender);

        data.append("city", cityselect?.Area);
        data.append("state", stateselect?.State);
        data.append("district", districtselect?.District);
        data.append("location_id", cityselect?._id);

        data.append("country", lostfinddata?.country);

        // data.append("description", lostfinddata?.description);
        if (fileName) {
          data.append("image", fileContant, fileName);
        }

        const token = localStorage.getItem("access_token");
        const config = {
          headers: {
            Authorization: "Bearer " + token,
          },
        };

        if (localStorage.getItem("access_token")) {
          const response = await Api.post(
            `${URL.createMarrigeProfile}`,
            data,
            config
          );

          if (response?.data?.success) {
            toast.success(response?.data?.message);
            navigate("/");
          } else {
            toast.error(response?.data?.message);
          }
        } else {
          const token = localStorage.getItem("access_token_vendor");
          const config = {
            headers: {
              Authorization: "Bearer " + token,
            },
          };
          const response = await Api.post(
            `${URL.createMarrigeProfilevender}`,
            data,
            config
          );

          if (response?.data?.success) {
            toast.success(response?.data?.message);
            navigate("/");
          } else {
            toast.error(response?.data?.message);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div class="jaishreeshyam">
        <h1>Matrimonial Form</h1>
        <div class="form-container">
          <h2>Your Personal Details</h2>
          <div class="form-row">
            <div class="form-group">
              <label for="profile-id">Profile Image</label>
              <div>
                {/* <h1>Image Cropper</h1> */}
                <ImageCropper
                  aspectRatio={4 / 3}
                  // onImageCrop={handleImageCrop}
                  setfileContant={setfileContant}
                  setFileName={setFileName}
                  setfinalImg={setfinalImg}
                  aspectwidth={16}
                  aspectheight={16}
                  onCropComplete={setCroppedImageUrl}
                />

                {croppedImageUrl && (
                  <div>
                    <img
                      src={croppedImageUrl}
                      alt="Cropped"
                      width={100}
                      height={100}
                    />
                  </div>
                )}
                {/* {finalImg && (
                    <div>
                      <img
                        src={finalImg}
                        alt="Cropped"
                        width={100}
                        height={130}
                      />
                    </div>
                  )} */}
              </div>
            </div>
          </div>
          {/* <div class="form-row">
            <div class="form-group">
              <label for="profile-id">Profile ID</label>
              <input type="text" id="profile-id" name="profile-id" />
            </div>
            <div class="form-group">
              <label for="user-id">User ID</label>
              <input type="text" id="user-id" name="user-id" />
            </div>
          </div> */}
          <div class="form-row">
            <div class="form-group">
              <label for="first-name">First Name</label>
              <input
                type="text"
                id="first-name"
                name="firstName"
                // value="Dinesh"
                value={lostfinddata?.firstName}
                required
                onChange={(e) => {
                  hendletochange(e);
                }}
              />
            </div>
            <div class="form-group">
              <label for="last-name">Last Name</label>
              <input
                type="text"
                id="last-name"
                name="lastName"
                // value="Singh"
                value={lostfinddata?.lastName}
                required
                onChange={(e) => {
                  hendletochange(e);
                }}
              />
            </div>
            <div class="form-group">
              <label for="gander">Gander</label>
              <select
                id="gander"
                name="gander"
                value={lostfinddata?.gander}
                required
                onChange={(e) => {
                  hendletochange(e);
                }}
              >
                <option value="">Please Select Gander</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </div>
            <div class="form-group">
              <label for="dob">Date Of Birth</label>
              <input
                type="date"
                id="dob"
                name="dob"
                // value="Singh"
                value={lostfinddata?.dob}
                required
                onChange={(e) => {
                  hendletochange(e);
                }}
              />
            </div>
            <div class="form-group">
              <label for="timeofbirths">Time Of Birth</label>
              <input
                type="time"
                id="timeofbirths"
                name="timeofbirths"
                value={lostfinddata?.timeofbirths}
                // value="Singh"
                required
                onChange={(e) => {
                  hendletochange(e);
                }}
              />
            </div>
            <div class="form-group">
              <label for="placeOfBirth">Place Of Birth</label>
              <input
                type="text"
                id="placeOfBirth"
                name="placeOfBirth"
                value={lostfinddata?.placeOfBirth}
                // value="Singh"
                required
                onChange={(e) => {
                  hendletochange(e);
                }}
              />
            </div>
            {/* <div class="form-group">
              <label for="nakshatra">Nakshatra</label>
              <input
                type="text"
                id="nakshatra"
                name="nakshatra"
                // value="Singh"
                required
                onChange={(e) => {
                  hendletochange(e);
                }}
              />
            </div> */}

            <div class="form-group">
              <label for="nakshatra">Nakshatra</label>
              <select
                name="nakshatra"
                id="nakshatra"
                class="drop"
                value={lostfinddata?.nakshatra}
                onChange={(e) => {
                  hendletochange(e);
                }}
              >
                <option selected="selected" value="">
                  Please Select
                </option>
                <option value="Ashwini (अश्विनी)">Ashwini (अश्विनी)</option>
                <option value="Bharani (भरणी)">Bharani ( भरणी)</option>
                <option value="Krittika (कृत्तिका)">Krittika (कृत्तिका)</option>
                <option value="Rohini (रोहिणी)">Rohini (रोहिणी)</option>
                <option value="Mrigashira (मृगशीर्ष)">
                  Mrigashira (मृगशीर्ष)
                </option>
                <option value="Ardra (आर्द्रा)">Ardra (आर्द्रा)</option>
                <option value="Punarvasu (पुनर्वसु)">
                  Punarvasu (पुनर्वसु)
                </option>
                <option value="Pushya (पुष्य)">Pushya (पुष्य)</option>
                <option value="Ashlesha (आश्लेषा)">Ashlesha (आश्लेषा)</option>
                <option value="Magha (मघा)">Magha (मघा)</option>
                <option value="Purva Phalguni (पूर्व फाल्गुनी)">
                  Purva Phalguni (पूर्व फाल्गुनी)
                </option>
                <option value="Uttara Phalguni (उत्तर फाल्गुनी)">
                  Uttara Phalguni (उत्तर फाल्गुनी)
                </option>
                <option value="Hasta (हस्त)">Hasta (हस्त)</option>
                <option value="Chitra (चित्रा)">Chitra (चित्रा)</option>
                <option value="Swati (स्वाति)">Swati (स्वाति)</option>
                <option value="Visakha (विशाखा)">Visakha (विशाखा)</option>
                <option value="Anuradha (अनुराधा)">Anuradha (अनुराधा)</option>
                <option value="Jyeshtha (ज्येष्ठा)">Jyeshtha (ज्येष्ठा)</option>
                <option value="Mula (मूल)">Mula (मूल)</option>
                <option value="Purva Ashadha (पूर्वाषाढ़ा)">
                  Purva Ashadha (पूर्वाषाढ़ा)
                </option>
                <option value="Uttara Ashadha (उत्तराषाढ़ा)">
                  Uttara Ashadha (उत्तराषाढ़ा)
                </option>
                <option value="Sravana (श्रवण)">Sravana (श्रवण)</option>
                <option value="Dhanishta (धनिष्ठा)">Dhanishta (धनिष्ठा)</option>
                <option value="Satabhisha (शतभिषा)">Satabhisha (शतभिषा)</option>
                <option value="Purva Bhadrapada (पूर्वभाद्रपद)">
                  Purva Bhadrapada (पूर्वभाद्रपद)
                </option>
                <option value="Uttara Bhadrapada (उत्तरभाद्रपद)">
                  Uttara Bhadrapada (उत्तरभाद्रपद)
                </option>
                <option value="Revati (रेवती)">Revati (रेवती)</option>
              </select>
            </div>
            <div class="form-group">
              <label for="Raasi">Raasi</label>
              <select
                name="Raasi"
                id="Raasi"
                class="drop"
                value={lostfinddata?.Raasi}
                onChange={(e) => {
                  hendletochange(e);
                }}
              >
                <option selected="selected" value="">
                  Please Select
                </option>
                <option value="Mesh (Aries)">Mesh (Aries)</option>
                <option value="Varishabha (Taurus)">Varishabha (Taurus)</option>
                <option value="Mithuna (Gemini)">Mithuna (Gemini)</option>
                <option value="Karka (Cancer)">Karka (Cancer)</option>
                <option value="Simha (Leo)">Simha (Leo)</option>
                <option value="Kanya (Virgo)">Kanya (Virgo)</option>
                <option value="Tula (Libra)">Tula (Libra)</option>
                <option value="Vrischika (Scorpio)">Vrischika (Scorpio)</option>
                <option value="Dhanur (Sagittarious)">
                  Dhanur (Sagittarious)
                </option>
                <option value="Makara (Capricorn)">Makara (Capricorn)</option>
                <option value="Kumbha (Aquarius)">Kumbha (Aquarius)</option>
                <option value="Meena (Pisces)">Meena (Pisces)</option>
              </select>
            </div>
            {/* <div class="form-group">
              <label for="Raasi">Raasi</label>
              <input
                type="text"
                id="Raasi"
                name="Raasi"
                // value="Singh"
                required
                onChange={(e) => {
                  hendletochange(e);
                }}
              />
            </div> */}
          </div>
          <div class="form-row">
            <div class="form-group">
              <label for="marital-status">Marital Status</label>
              <select
                id="marital-status"
                name="maritalStatus"
                required
                value={lostfinddata?.maritalStatus}
                onChange={(e) => {
                  hendletochange(e);
                }}
              >
                <option value="">Please Select</option>
                <option value="never-married">Never Married</option>
                <option value="widow">Widow</option>
                <option value="widower">Widower</option>
                <option value="divorce">Divorce</option>
                <option value="unfertile">Unfertile</option>
              </select>
            </div>
            <div class="form-group">
              <label for="religion">Religion</label>
              <select
                id="religion"
                name="religion"
                required
                value={lostfinddata?.religion}
                onChange={(e) => {
                  hendletochange(e);
                }}
              >
                <option value="">Please Select</option>
                <option value="Hindu">Hindu</option>
                <option value="Islam">Islam</option>
                <option value="Christianity">Christianity</option>
                <option value="Sikhism">Sikhism</option>
                <option value="Buddhism">Buddhism</option>
                <option value="Jainism">Jainism</option>
                <option value="Zoroastrianism">Zoroastrianism</option>
                <option value="Judaism">Judaism</option> 
                <option value="Sanamahism">Sanamahism</option>
                <option value="Animism">Animism</option>
                <option value="Sarnaism">Sarnaism</option>
              </select>
              {/* <input
                type="text"
                id="religion"
                name="religion"
                value={lostfinddata?.religion}
                onChange={(e) => {
                  hendletochange(e);
                }}
              /> */}
            </div>
          </div>
          <div class="form-row">
            <div class="form-group">
              <label for="manglik">Manglik</label>
              <select
                name="manglik"
                id="drp_manglik"
                value={lostfinddata?.manglik}
                class="drop"
                onChange={(e) => {
                  hendletochange(e);
                }}
              >
                <option selected="selected" value="">
                  Please Select
                </option>
                <option value="MANGLI">MANGLI</option>
                <option value="NON MANGLI">NON MANGLI</option>
                <option value="ANSHIK MANGLI">ANSHIK MANGLI</option>
              </select>
            </div>
            <div class="form-group">
              <label for="caste">Caste</label>
              <select
                name="caste"
                id="drp_caste"
                value={lostfinddata?.caste}
                class="drop"
                onChange={(e) => {
                  hendletochange(e);
                }}
              >
                <option value="Please Select">Please Select</option>
                <option value="Vaish">Vaish</option>
                <option value="Brahmin">Brahmin</option>
                <option value="PUNJABI">PUNJABI</option>
                <option value="Jatav">Jatav</option>
                <option value="Please Select">Please Select</option>
                <option value="Agaria - Hindu">Agaria - Hindu</option>
                <option value="Arora ">Arora </option>
                <option value="Arora - Sikh">Arora - Sikh</option>
                <option value="Badhai - Hindu">Badhai - Hindu</option>
                <option value="Badhi - Hindu">Badhi - Hindu</option>
                <option value="Balmiki">Balmiki</option>
                <option value="Bangali - Hindu">Bangali - Hindu</option>
                <option value="Banjara - Hindu">Banjara - Hindu</option>
                <option value="Banjara - Hindu (Nayak)">
                  Banjara - Hindu (Nayak)
                </option>
                <option value="Bari - Hindu">Bari - Hindu</option>
                <option value="Barman">Barman</option>
                <option value="Bhoi - Hindu">Bhoi - Hindu</option>
                <option value="Brahma Kshatriya">Brahma Kshatriya</option>
                <option value="Brahmin - Dhiman">Brahmin - Dhiman</option>
                <option value="Brahmin - Kanaujia">Brahmin - Kanaujia</option>
                <option value="Brahmin - Sanadhya">Brahmin - Sanadhya</option>
                <option value="Chamar">Chamar</option>
                <option value="Chamar | Ravidasia">Chamar | Ravidasia</option>
                <option value="Chhipi">Chhipi</option>
                <option value="Christian unspecified">
                  Christian unspecified
                </option>
                <option value="Dhanuk">Dhanuk</option>
                <option value="Dheevar">Dheevar</option>
                <option value="Dhimar">Dhimar</option>
                <option value="Dhobi">Dhobi</option>
                <option value="Dhobi - Hindu">Dhobi - Hindu</option>
                <option value="Gadaria">Gadaria</option>
                <option value="Gadaria - Hindu">Gadaria - Hindu</option>
                <option value="Gadariya">Gadariya</option>
                <option value="Gangwar">Gangwar</option>
                <option value="Gond">Gond</option>
                <option value="Gosain - Hindu">Gosain - Hindu</option>
                <option value="Goswami Brahmin">Goswami Brahmin</option>
                <option value="Gounder">Gounder</option>
                <option value="Gowari">Gowari</option>
                <option value="Guria">Guria</option>
                <option value="Gurjar">Gurjar</option>
                <option value="HP">HP</option>
                <option value="JAAT">JAAT</option>
                <option value="Jain">Jain</option>
                <option value="Jaiswar">Jaiswar</option>
                <option value="Jogi - Hindu">Jogi - Hindu</option>
                <option value="Julaha">Julaha</option>
                <option value="Julaha Hindu">Julaha Hindu</option>
                <option value="Jyotish Brahmin">Jyotish Brahmin</option>
                <option value="Jyotishi Brahmin">Jyotishi Brahmin</option>
                <option value="Kamboj ">Kamboj </option>
                <option value="Kapu">Kapu</option>
                <option value="Kashmiri Sikh">Kashmiri Sikh</option>
                <option value="Kashyap">Kashyap</option>
                <option value="KAYASTH">KAYASTH</option>
                <option value="Kayastha">Kayastha</option>
                <option value="Khateek">Khateek</option>
                <option value="Khatik - Hindu">Khatik - Hindu</option>
                <option value="Khatri">Khatri</option>
                <option value="Khatri - Sikh">Khatri - Sikh</option>
                <option value="Khatris">Khatris</option>
                <option value="Koli">Koli</option>
                <option value="Koli Mahadev">Koli Mahadev</option>
                <option value="Kori">Kori</option>
                <option value="Kori (S.C.)">Kori (S.C.)</option>
                <option value="Kshatriya">Kshatriya</option>
                <option value="Kshatriya-Thakur">Kshatriya-Thakur</option>
                <option value="Kumauni Brahmin">Kumauni Brahmin</option>
                <option value="Kumauni Rajput">Kumauni Rajput</option>
                <option value="Kumhar">Kumhar</option>
                <option value="Kumhar | Prajapati">Kumhar | Prajapati</option>
                <option value="Kunbi">Kunbi</option>
                <option value="Kurmi">Kurmi</option>
                <option value="Kushwaha">Kushwaha</option>
                <option value="Lodhi Rajput">Lodhi Rajput</option>
                <option value="Lohar">Lohar</option>
                <option value="Mali">Mali</option>
                <option value="Maurya">Maurya</option>
                <option value="Mudaliar">Mudaliar</option>
                <option value="Musahar - Hindu">Musahar - Hindu</option>
                <option value="MUSLIM">MUSLIM</option>
                <option value="Nai">Nai</option>
                <option value="Nair">Nair</option>
                <option value="Nishad">Nishad</option>
                <option value="Other">Other</option>
                <option value="Pal">Pal</option>
                <option value="Parekh">Parekh</option>
                <option value="Pasi (Schedule)">Pasi (Schedule)</option>
                <option value="Pasi - Hindu">Pasi - Hindu</option>
                <option value="Prajapati">Prajapati</option>
                <option value="Prajapati (Kumhar)">Prajapati (Kumhar)</option>
                <option value="Punjabi Brahmin">Punjabi Brahmin</option>
                <option value="Rajpur">Rajpur</option>
                <option value="Rajput">Rajput</option>
                <option value="Rajput - Bais - Hindu">
                  Rajput - Bais - Hindu
                </option>
                <option value="Rajput - Bhatti - Hindu">
                  Rajput - Bhatti - Hindu
                </option>
                <option value="Ramgarhia">Ramgarhia</option>
                <option value="Rawat">Rawat</option>
                <option value="Sahu">Sahu</option>
                <option value="Saini">Saini</option>
                <option value="Sarera - Sikh">Sarera - Sikh</option>
                <option value="Sauria Paharia">Sauria Paharia</option>
                <option value="Select Caste">Select Caste</option>
                <option value="Shah">Shah</option>
                <option value="Shakya ">Shakya </option>
                <option value="Sikh">Sikh</option>
                <option value="Sikh - Jatt">Sikh - Jatt</option>
                <option value="Sikh Arora / Khatri">Sikh Arora / Khatri</option>
                <option value="Sikh Jat">Sikh Jat</option>
                <option value="Silpkar - Hindu">Silpkar - Hindu</option>
                <option value="Sindhi">Sindhi</option>
                <option value="Sindhi Vaish">Sindhi Vaish</option>
                <option value="Somvanshi Rajput">Somvanshi Rajput</option>
                <option value="Sonar">Sonar</option>
                <option value="Sonar (Marathi Goldsmith Caste)">
                  Sonar (Marathi Goldsmith Caste)
                </option>
                <option value="Sonar - Muslim">Sonar - Muslim</option>
                <option value="Sonar - Rastogi">Sonar - Rastogi</option>
                <option value="Sonker">Sonker</option>
                <option value="Sonr">Sonr</option>
                <option value="Tamboli - Hindu">Tamboli - Hindu</option>
                <option value="Tarkhan">Tarkhan</option>
                <option value="Teli">Teli</option>
                <option value="Tyagi">Tyagi</option>
                <option value="Ujia">Ujia</option>
                <option value="UP Khatri">UP Khatri</option>
                <option value="Vaish - Gahoi">Vaish - Gahoi</option>
                <option value="Valmiki">Valmiki</option>
                <option value="Viswakarma">Viswakarma</option>
                <option value="Yadav">Yadav</option>
              </select>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group">
              <label for="sub-caste">Sub Caste</label>
              <input
                type="text"
                id="sub-caste"
                name="subCaste"
                value={lostfinddata?.subCaste}
                onChange={(e) => {
                  hendletochange(e);
                }}
              />
            </div>
            <div class="form-group">
              <label for="gothra">Gothra</label>
              <input
                type="text"
                id="gothra"
                name="gothra"
                value={lostfinddata?.gothra}
                onChange={(e) => {
                  hendletochange(e);
                }}
              />
            </div>
          </div>

          <h2>Information About Residential Status</h2>
          <div class="form-row">
            {/* <div class="form-group">
              <label for="country-residence">Country of Residence</label>
              <select name="country" id="drp_Nationality" class="drop">
                <option value="Indian">Indian</option>
                <option value="NRI">NRI</option>
              </select>
            </div> */}
            <div class="form-group">
              <label for="state-residence">State of Residence</label>
              <AsyncPaginate
                value={stateselect}
                className="liceninput2"
                placeholder="State"
                loadOptions={loadOptionsState}
                onChange={setstateselect}
                additional={{
                  page: 1,
                }}
              />
              {/* <input type="text" id="state-residence" name="state-residence" /> */}
            </div>
            <div class="form-group">
              <label for="state-residence">District of Residence</label>
              <AsyncPaginate
                key={mangeKey}
                value={districtselect}
                className="liceninput2"
                placeholder="District"
                loadOptions={loadOptionsDisrcit}
                onChange={setdistrictselect}
                additional={{
                  page: 1,
                }}
              />
              {/* <input type="text" id="state-residence" name="state-residence" /> */}
            </div>
          </div>
          <div class="form-row">
            <div class="form-group">
              <label for="city-residence">City of Residence</label>
              <AsyncPaginate
                key={mangeKey2}
                value={cityselect}
                className="liceninput2"
                placeholder="City"
                loadOptions={loadOptionsCity}
                onChange={setcityselect}
                additional={{
                  page: 1,
                }}
              />
              {/* <select id="city-residence" name="city-residence" required>
                <option value="">Please Select</option>
                <option value="city1">City 1</option>
                <option value="city2">City 2</option>
              </select> */}
            </div>
            <div class="form-group">
              <label for="postal-address">Postal Address</label>
              <input
                type="text"
                id="postal-address"
                name="address"
                value={lostfinddata?.address}
                onChange={(e) => {
                  hendletochange(e);
                }}
              />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group">
              <label for="correspondence-address">
                Correspondence Address (if any)
              </label>
              <input
                type="text"
                value={lostfinddata?.address2}
                id="correspondence-address"
                name="address2"
                onChange={(e) => {
                  hendletochange(e);
                }}
              />
            </div>
          </div>

          <h2>Information about Occupation & Education</h2>
          <div class="form-row">
            <div class="form-group">
              <label for="education">Education</label>
              <select
                name="education"
                id="drp_education"
                value={lostfinddata?.education}
                class="drop"
                onChange={(e) => {
                  hendletochange(e);
                }}
              >
                <option value="Please Select">Please Select</option>
                <option value="Bachelors">Bachelors</option>
                <option value="Masters">Masters</option>
                <option value="Doctorate">Doctorate</option>
                <option value="Diploma">Diploma</option>
                <option value="Under Graduate">Under Graduate</option>
                <option value="Honours Degree">Honours Degree</option>
                <option value="InterMediate">InterMediate</option>
                <option value="High School">High School</option>
                <option value="Less Than High School">
                  Less Than High School
                </option>
              </select>
            </div>
            <div class="form-group">
              <label for="educational-area">Educational Area</label>
              <select
                name="educationalArea"
                id="drp_educationArea"
                class="drop"
                value={lostfinddata?.educationalArea}
                onChange={(e) => {
                  hendletochange(e);
                }}
              >
                <option value="Please Select">Please Select</option>
                <option value="Administrative services">
                  Administrative services
                </option>
                <option value="Advertising/ Marketing">
                  Advertising/ Marketing
                </option>
                <option value="Architecture">Architecture</option>
                <option value="Armed Forces">Armed Forces</option>
                <option value="Arts">Arts</option>
                <option value="Commerce">Commerce</option>
                <option value="Computers/ IT">Computers/ IT</option>
                <option value="Education">Education</option>
                <option value="Engineering/ Management">
                  Engineering/ Management
                </option>
                <option value="Engineering/ Technology">
                  Engineering/ Technology
                </option>
                <option value="Fashion">Fashion</option>
                <option value="Finance">Finance</option>
                <option value="Fine Arts">Fine Arts</option>
                <option value="Home Science">Home Science</option>
                <option value="Law">Law</option>
                <option value="Management">Management</option>
                <option value="Medicine">Medicine</option>
                <option value="Nursing/ Health Sciences">
                  Nursing/ Health Sciences
                </option>
                <option value="Office administration">
                  Office administration
                </option>
                <option value="Science">Science</option>
                <option value="Shipping">Shipping</option>
                <option value="Travel&amp;Tourism">Travel&amp;Tourism</option>
              </select>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group">
              <label for="occupation-type">Occupation Type</label>
              <select
                name="occupationType"
                value={lostfinddata?.occupationType}
                id="drp_occ_type"
                class="drop"
                onChange={(e) => {
                  hendletochange(e);
                }}
              >
                <option selected="selected" value="Please Select">
                  Please Select
                </option>
                <option value="Private">Private</option>
                <option value="Government / Public Sector">
                  Government / Public Sector
                </option>
                <option value="Business / Self Employed">
                  Business / Self Employed
                </option>
                <option value="Not Working">Not Working</option>
              </select>
            </div>
            <div class="form-group">
              <label for="occupation-category">Occupation Category</label>
              <select
                name="occupationCategory"
                value={lostfinddata?.occupationCategory}
                id="drp_occupationCategory"
                class="drop"
                onChange={(e) => {
                  hendletochange(e);
                }}
              >
                <option selected="selected" value="Please Select">
                  Please Select
                </option>
                <option value="Accounting, Banking &amp; Finance">
                  Accounting, Banking &amp; Finance
                </option>
                <option value="Administration &amp; HR">
                  Administration &amp; HR
                </option>
                <option value="Advertising, Media &amp; Entertainment">
                  Advertising, Media &amp; Entertainment
                </option>
                <option value="Agriculture">Agriculture</option>
                <option value="Airline &amp; Aviation">
                  Airline &amp; Aviation
                </option>
                <option value="Architecture &amp; Design">
                  Architecture &amp; Design
                </option>
                <option value="Artists, Animators &amp; Web Designers">
                  Artists, Animators &amp; Web Designers
                </option>
                <option value="Beauty, Fashion &amp; Jewellery Designers">
                  Beauty, Fashion &amp; Jewellery Designers
                </option>
                <option value="BPO, KPO, &amp; Customer Support">
                  BPO, KPO, &amp; Customer Support
                </option>
                <option value="Business">Business</option>
                <option value="Civil Services / Law Enforcement">
                  Civil Services / Law Enforcement
                </option>
                <option value="Corporate Professionals">
                  Corporate Professionals
                </option>
                <option value="Defence">Defence</option>
                <option value="Education &amp; Training">
                  Education &amp; Training
                </option>
                <option value="Engineering">Engineering</option>
                <option value="Hotel &amp; Hospitality">
                  Hotel &amp; Hospitality
                </option>
                <option value="IT &amp; Software Engineering">
                  IT &amp; Software Engineering
                </option>
                <option value="Legal">Legal</option>
                <option value="Medical &amp; Healthcare">
                  Medical &amp; Healthcare
                </option>
                <option value="Merchant Navy">Merchant Navy</option>
                <option value="Non Working">Non Working</option>
                <option value="Others">Others</option>
                <option value="Railway">Railway</option>
                <option value="Sales &amp; Marketing">
                  Sales &amp; Marketing
                </option>
                <option value="Science">Science</option>
              </select>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group">
              <label for="occupation">Occupation</label>
              <input
                type="text"
                id="occupation"
                name="occupation"
                value={lostfinddata?.occupation}
                onChange={(e) => {
                  hendletochange(e);
                }}
              />
            </div>
            <div class="form-group">
              <label for="annual-income">Annual Income</label>
              <select
                name="annualIncome"
                value={lostfinddata?.annualIncome}
                id="CboNewIncome"
                tabindex="21"
                class="drp"
                onChange={(e) => {
                  hendletochange(e);
                }}
              >
                <option value="1">Up To INR 1 Lakh</option>
                <option value="2">INR 1 Lakh To Less Than 2 Lakh</option>
                <option value="4">INR 2 Lakh To Less Than 4 Lakh</option>
                <option value="7">INR 4 Lakh To Less Than 7 Lakh</option>
                <option value="10">INR 7 Lakh To Less Than 10 Lakh</option>
                <option value="15">INR 10 Lakh To 15 Lakh</option>
                <option value="20">INR 15 Lakh To Less Than 20 Lakh</option>
                <option value="30">INR 20 Lakh To Less Than 30 Lakh</option>
                <option value="50">INR 30 Lakh To Less Than 50 Lakh</option>
                <option value="75">INR 50 Lakh To Less Than 75 Lakh</option>
                <option value="100">INR 75 Lakh To Less Than 1 Crore</option>
                <option value="101">INR 1 Crore To Above</option>
                <option value="0">Do Not Show</option>
              </select>
            </div>
          </div>

          <h2>Other Information About Yourself</h2>
          <div class="form-row">
            <div class="form-group">
              <label for="bodytype">Features (Body Type)</label>
              <select
                id="bodytype"
                name="featuresBodyType"
                value={lostfinddata?.featuresBodyType}
                onChange={(e) => {
                  hendletochange(e);
                }}
                required
              >
                <option value="">Please Select</option>
                <option value="beautiful">Beautiful</option>
                <option value="handsome">Handsome</option>
              </select>
            </div>
            <div class="form-group">
              <label for="complexion">Complexion</label>
              <select
                id="complexion"
                name="complexion"
                value={lostfinddata?.complexion}
                onChange={(e) => {
                  hendletochange(e);
                }}
                required
              >
                <option value="">Please Select</option>
                <option value="fair">Fair</option>
                <option value="dark">Dark</option>
                <option value="wheatish">Wheatish</option>
              </select>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group">
              <label for="built">Built (Structure)</label>
              <select
                id="built"
                name="builtStructure"
                value={lostfinddata?.builtStructure}
                onChange={(e) => {
                  hendletochange(e);
                }}
                required
              >
                <option value="">Please Select</option>
                <option value="slim">Slim</option>
                <option value="thin">Thin</option>
                <option value="medium">Medium</option>
                <option value="heavy">Heavy</option>
              </select>
            </div>
            <div class="form-group">
              <label for="special-cases">Special Cases</label>
              <select
                id="special-cases"
                name="specialCases"
                value={lostfinddata?.specialCases}
                onChange={(e) => {
                  hendletochange(e);
                }}
              >
                <option value="">Please Select</option>
                <option value="case1">Case 1</option>
                <option value="case2">Case 2</option>
              </select>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group">
              <label for="blood-group">Blood Group</label>
              <select
                id="blood-group"
                name="bloodGroup"
                value={lostfinddata?.bloodGroup}
                onChange={(e) => {
                  hendletochange(e);
                }}
                required
              >
                <option value="">Please Select</option>
                <option value="a+">A+</option>
                <option value="a-">A-</option>
                <option value="b+">B+</option>
                <option value="b-">B-</option>
                <option value="o+">O+</option>
                <option value="o-">O-</option>
                <option value="ab+">AB+</option>
                <option value="ab-">AB-</option>
              </select>
            </div>
            <div class="form-group">
              <label for="body-weight">Body Weight (in Kgs)</label>
              <input
                type="number"
                id="body-weight"
                name="bodyWeight"
                value={lostfinddata?.bodyWeight}
                onChange={(e) => {
                  hendletochange(e);
                }}
              />
            </div>

            <div class="form-group">
              <label for="height">Height</label>
              <select
                id="height"
                name="height"
                value={lostfinddata?.height}
                onChange={(e) => {
                  hendletochange(e);
                }}
                required
              >
                <option value="">Please Select</option>
                <option value="3 feet 5 inches">3 feet 5 inches</option>
                <option value="3 feet 6 inches">3 feet 6 inches</option>
                <option value="3 feet 7 inches">3 feet 7 inches</option>
                <option value="3 feet 8 inches">3 feet 8 inches</option>
                <option value="3 feet 9 inches">3 feet 9 inches</option>
                <option value="3 feet 10 inches">3 feet 10 inches</option>
                <option value="3 feet 11 inches">3 feet 11 inches</option>
                <option value="4 feet">4 feet</option>
                <option value="4 feet 1 inch">4 feet 1 inch</option>
                <option value="4 feet 2 inches">4 feet 2 inches</option>
                <option value="4 feet 3 inches">4 feet 3 inches</option>
                <option value="4 feet 4 inches">4 feet 4 inches</option>
                <option value="4 feet 5 inches">4 feet 5 inches</option>
                <option value="4 feet 6 inches">4 feet 6 inches</option>
                <option value="4 feet 7 inches">4 feet 7 inches</option>
                <option value="4 feet 8 inches">4 feet 8 inches</option>
                <option value="4 feet 9 inches">4 feet 9 inches</option>
                <option value="4 feet 10 inches">4 feet 10 inches</option>
                <option value="4 feet 11 inches">4 feet 11 inches</option>
                <option value="5 feet">5 feet</option>
                <option value="5 feet 1 inch">5 feet 1 inch</option>
                <option value="5 feet 2 inches">5 feet 2 inches</option>
                <option value="5 feet 3 inches">5 feet 3 inches</option>
                <option value="5 feet 4 inches">5 feet 4 inches</option>
                <option value="5 feet 5 inches">5 feet 5 inches</option>
                <option value="5 feet 6 inches">5 feet 6 inches</option>
                <option value="5 feet 7 inches">5 feet 7 inches</option>
                <option value="5 feet 8 inches">5 feet 8 inches</option>
                <option value="5 feet 9 inches">5 feet 9 inches</option>
                <option value="5 feet 10 inches">5 feet 10 inches</option>
                <option value="5 feet 11 inches">5 feet 11 inches</option>
                <option value="6 feet">6 feet</option>
                <option value="6 feet 1 inch">6 feet 1 inch</option>
                <option value="6 feet 2 inches">6 feet 2 inches</option>
                <option value="6 feet 3 inches">6 feet 3 inches</option>
                <option value="6 feet 4 inches">6 feet 4 inches</option>
                <option value="6 feet 5 inches">6 feet 5 inches</option>
                <option value="6 feet 6 inches">6 feet 6 inches</option>
                <option value="6 feet 7 inches">6 feet 7 inches</option>
                <option value="6 feet 8 inches">6 feet 8 inches</option>
                <option value="6 feet 9 inches">6 feet 9 inches</option>
                <option value="6 feet 10 inches">6 feet 10 inches</option>
                <option value="6 feet 11 inches">6 feet 11 inches</option>
                <option value="7 feet">7 feet</option>
                <option value="7 feet 1 inch">7 feet 1 inch</option>
                <option value="7 feet 2 inches">7 feet 2 inches</option>
                <option value="7 feet 3 inches">7 feet 3 inches</option>
                <option value="7 feet 4 inches">7 feet 4 inches</option>
                <option value="7 feet 5 inches">7 feet 5 inches</option>
                <option value="7 feet 6 inches">7 feet 6 inches</option>
                <option value="7 feet 7 inches">7 feet 7 inches</option>
                <option value="7 feet 8 inches">7 feet 8 inches</option>
                <option value="7 feet 9 inches">7 feet 9 inches</option>
                <option value="7 feet 10 inches">7 feet 10 inches</option>
                <option value="7 feet 11 inches">7 feet 11 inches</option>
                <option value="8 feet">8 feet</option>
              </select>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group">
              <label for="smoke">Smoke</label>
              <select
                id="smoke"
                name="smoke"
                value={lostfinddata?.smoke}
                onChange={(e) => {
                  hendletochange(e);
                }}
                required
              >
                <option value="">Please Select</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
                <option value="occasionally">Occasionally</option>
              </select>
            </div>
            <div class="form-group">
              <label for="eating-habits">
                Your Eating Habits (Veg/Non-Veg)
              </label>
              <select
                id="eating-habits"
                name="yourEatingHabits"
                value={lostfinddata?.yourEatingHabits}
                onChange={(e) => {
                  hendletochange(e);
                }}
                required
              >
                <option value="">Please Select</option>
                <option value="vegetarian">Vegetarian</option>
                <option value="non-vegetarian">Non Vegetarian</option>
              </select>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group">
              <label for="hard-drinks">Take Hard Drinks</label>
              <select
                id="hard-drinks"
                name="takeHardDrinks"
                value={lostfinddata?.takeHardDrinks}
                onChange={(e) => {
                  hendletochange(e);
                }}
                required
              >
                <option value="">Please Select</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div>
          </div>
          <h2>Patner Preference</h2>
          <div class="form-row">
            <div class="form-group">
              <label for="marital-status">Marital Status</label>
              <select
                id="marital-status"
                name="ppmaritalStatus"
                required
                value={lostfinddata?.ppmaritalStatus}
                onChange={(e) => {
                  hendletochange(e);
                }}
              >
                <option value="">Please Select</option>
                <option value="never-married">Never Married</option>
                <option value="widow">Widow</option>
                <option value="widower">Widower</option>
                <option value="divorce">Divorce</option>
                <option value="unfertile">Unfertile</option>
              </select>
            </div>
            <div class="form-group">
              <label for="ageRange">Age Range</label>
              <input
                type="text"
                id="ageRange"
                name="ageRange"
                value={lostfinddata?.ageRange?.join(",")}
                // value={lostfinddata?.religion}
                placeholder="e.g. 25,30"
                onChange={(e) => {
                  hendletochange(e);
                }}
              />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group">
              <label for="bodytype">Features (Body Type)</label>
              <select
                id="bodytype"
                name="ppfeaturesBodyType"
                value={lostfinddata?.ppfeaturesBodyType}
                onChange={(e) => {
                  hendletochange(e);
                }}
                required
              >
                <option value="">Please Select</option>
                <option value="beautiful">Beautiful</option>
                <option value="handsome">Handsome</option>
              </select>
            </div>
            <div class="form-group">
              <label for="complexion">Complexion</label>
              <select
                id="complexion"
                name="ppcomplexion"
                value={lostfinddata?.ppcomplexion}
                onChange={(e) => {
                  hendletochange(e);
                }}
                required
              >
                <option value="">Please Select</option>
                <option value="fair">Fair</option>
                <option value="dark">Dark</option>
                <option value="wheatish">Wheatish</option>
              </select>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group">
              <label for="smoke">Smoke</label>
              <select
                id="smoke"
                name="ppsmoke"
                value={lostfinddata?.ppsmoke}
                onChange={(e) => {
                  hendletochange(e);
                }}
                required
              >
                <option value="">Please Select</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
                <option value="occasionally">Occasionally</option>
              </select>
            </div>
            <div class="form-group">
              <label for="eating-habits">
                Your Eating Habits (Veg/Non-Veg)
              </label>
              <select
                id="eating-habits"
                name="ppyourEatingHabits"
                value={lostfinddata?.ppyourEatingHabits}
                onChange={(e) => {
                  hendletochange(e);
                }}
                required
              >
                <option value="">Please Select</option>
                <option value="vegetarian">Vegetarian</option>
                <option value="non-vegetarian">Non Vegetarian</option>
              </select>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group">
              <label for="hard-drinks">Take Hard Drinks</label>
              <select
                id="hard-drinks"
                name="pptakeHardDrinks"
                value={lostfinddata?.pptakeHardDrinks}
                onChange={(e) => {
                  hendletochange(e);
                }}
                required
              >
                <option value="">Please Select</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div>
          </div>

          <div class="form-actions">
            <button type="submit" onClick={(e) => hendleTosubmit(e)}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WeddingForm;
