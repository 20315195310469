import React from 'react'

function WeddingAbout() {
    return (
        <div>

            <div class="weddingportal">
                <div className="center_main position-relative">
                    <section>
                        <div class="plans-ban">
                            <div class="container">
                                <div class="row">
                                    <span class="pri pt-30">#1 Wedding Website</span>
                                    <h1>About us </h1>
                                    <p>Most Trusted and premium Matrimony Service in the World.</p>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <section>
                    <div class="ab-sec2">
                        <div class="container">
                            <div class="row">
                                <ul>
                                    <li>
                                        <div>
                                            <img src="https://rn53themes.net/themes/matrimo/images/icon/prize.png" alt="" />
                                            <h4>Genuine profiles</h4>
                                            <p>The most trusted wedding matrimony brand</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <img src="https://rn53themes.net/themes/matrimo/images/icon/trust.png" alt="" />
                                            <h4>Most trusted</h4>
                                            <p>The most trusted wedding matrimony brand</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <img src="https://rn53themes.net/themes/matrimo/images/icon/rings.png" alt="" />
                                            <h4>2000+ weddings</h4>
                                            <p>The most trusted wedding matrimony brand</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>

                <div class="row p-10">
                    <div class="col-md-6 p-20">
                        <div class="ab-wel-lhs">
                            <span class="ab-wel-3"></span>
                            <img src="https://rn53themes.net/themes/matrimo/images/about/1.jpg" alt="" class="ab-wel-1" />
                            <img src="https://rn53themes.net/themes/matrimo/images/about/2.jpg" alt="" class="ab-wel-2" />
                            <span class="ab-wel-4"></span>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <h2>Welcome to</h2>
                        <h2>Wedding matrimony</h2>
                        <p>Best wedding matrimony It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                        <hr>
                        </hr>
                        <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                        <p>
                            <span>
                                <i class="fa fa-phone wedding_about_icon" aria-hidden="true"></i>&nbsp;&nbsp; Enquiry<br></br>&nbsp;&nbsp; &nbsp;&nbsp;
                                +01 2242 3366
                            </span>
                        </p>
                        <p>
                            <span>
                                <i class="fa fa-envelope wedding_about_icon" aria-hidden="true"></i> &nbsp;&nbsp; Get Support<br></br>&nbsp;&nbsp; &nbsp;&nbsp;
                                info@example.com

                            </span>
                        </p>
                    </div>

                </div>
                <br></br>
                <hr></hr>
                {/* <section>
                    <div class="ab-cont">
                        <div class="container">
                            <div class="row">
                                <ul>
                                    <li>
                                        <div class="ab-cont-po">
                                            <i class="fa fa-heart" aria-hidden="true"></i>
                                            <div>
                                                <h4>2K</h4>
                                                <span>Couples pared</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="ab-cont-po">
                                            <i class="fa fa-users" aria-hidden="true"></i>
                                            <div>
                                                <h4>4000+</h4>
                                                <span>Registered users</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="ab-cont-po">
                                            <i class="fa fa-male" aria-hidden="true"></i>
                                            <div>
                                                <h4>1600+</h4>
                                                <span>Mens</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="ab-cont-po">
                                            <i class="fa fa-female" aria-hidden="true"></i>
                                            <div>
                                                <h4>2000+</h4>
                                                <span>Womens</span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section> */}
            </div>
        </div>
    )
}

export default WeddingAbout