import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

function Wedding() {
  const [first, setfirst] = useState({});
  const hendleTochange = (e) => {
    const { name, value } = e.target;
    setfirst({ ...first, [name]: value });
  };
  const navigate = useNavigate();

  const handleClick = () => {
    const data = { message: 'Hello from Page One!' };

    if (first?.looking) {
      data.gander  = first?.looking;
    }
    if (first?.agefrom) {
      data.agefrom  = first?.agefrom;
    }
    if (first?.religion) {
      data.religion  = first?.religion;
    }
    navigate('/wedding/list', { state: data });
  };
  return (
    <div>
      <div class="weddingportal">
        <div className="center_main position-relative">
          {/* <img
            src="/img/wedding/3.jpg"
            className="d-block w-100"
            alt="..."
            style={{ height: "400px" }}
          /> */}
          <img
            src="/img/wedding/3.jpg"
            className="d-block w-100"
            alt="..."
            style={{ maxHeight: "400px", objectFit: "cover" }}
          />

          <div className="carousel-caption d-md-block">
            <div class="b">
              <div class="search-bar text-center">
                {/* <h2>Find your <span style="color: red;">Right Match</span> here</h2> */}
                <form class="form-inline justify-content-center">
                  <div class="j">
                    <select
                      class="form-control "
                      required
                      name="looking"
                      onChange={(e) => {
                        hendleTochange(e);
                      }}
                    >
                      <option value="">Select I'm Looking For</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      {/* <!-- Add options here --> */}
                    </select>
                    <input
                      type="number"
                      class="form-control"
                      placeholder="Age From"
                      min="18"
                      required
                      name="agefrom"
                      onChange={(e) => {
                        hendleTochange(e);
                      }}
                    />
                    <select class="form-control" name="religion"  onChange={(e) => {
                        hendleTochange(e);
                      }} required>
                      <option value="">Select Religion</option>
               
                      <option value="Hindu">Hindu</option>
                <option value="Islam">Islam</option>
                <option value="Christianity">Christianity</option>
                <option value="Sikhism">Sikhism</option>
                <option value="Buddhism">Buddhism</option>
                <option value="Jainism">Jainism</option>
                <option value="Zoroastrianism">Zoroastrianism</option>
                <option value="Judaism">Judaism</option> 
                <option value="Sanamahism">Sanamahism</option>
                <option value="Animism">Animism</option>
                <option value="Sarnaism">Sarnaism</option>
                    </select>
                    {/* <select class="form-control" required>
                      <option value="">Select Mother Tongue</option>
                     
                    </select> */}
                    <button type="submit" class="btn btn-primary" onClick={()=>{handleClick()}}>
                      Search
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="center_main position-relative">
<section id="center" className="center_home">
 <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
   <div className="carousel-indicators">
     <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-label="Slide 1" aria-current="true"></button>
     <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
     <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
   </div>
   <div className="carousel-inner">
     <div className="carousel-item active">
       <img src="/img/wedding/3.jpg" className="d-block w-100" alt="..." />
       <div className="carousel-caption d-md-block">
         <span className="col_red font_60 lh-1"><i className="fa fa-leaf"></i></span>
         <h1 className="text-white font_60 mt-3">Made With <span className="col_red">Love</span></h1>
         <p>Ac mi duis mollis. Sapiente? Scelerisque quae, penatibus? Suscipit class corporis nostra rem quos voluptatibus habitant? Fames, vivamus minim nemo enim, gravida lobortis quasi, eum.</p>
         <h6 className="mb-0 mt-4"><a className="button" href="#">We Can Help</a></h6>
       </div>
     </div>
     <div className="carousel-item">
       <img src="/img/wedding/3.jpg" className="d-block w-100" alt="..." />
       <div className="carousel-caption carousel-caption1 d-md-block">
         <span className="col_red font_60 lh-1"><i className="fa fa-leaf"></i></span>
         <h1 className="text-white font_60 mt-3">Lorem and <span className="col_red">Dolor</span></h1>
         <p>Ac mi duis mollis. Sapiente? Scelerisque quae, penatibus? Suscipit class corporis nostra rem quos voluptatibus habitant? Fames, vivamus minim nemo enim, gravida lobortis quasi, eum.</p>
         <h6 className="mb-0 mt-4"><a className="button" href="#">Online Request</a></h6>
       </div>
     </div>
     <div className="carousel-item">
       <img src="/img/wedding/3.jpg" className="d-block w-100" alt="..." />
       <div className="carousel-caption carousel-caption2 d-md-block">
         <span className="col_red font_60 lh-1"><i className="fa fa-leaf"></i></span>
         <h1 className="text-white font_60 mt-3">Our Forever <span className="col_red">After</span></h1>
         <p>Ac mi duis mollis. Sapiente? Scelerisque quae, penatibus? Suscipit class corporis nostra rem quos voluptatibus habitant? Fames, vivamus minim nemo enim, gravida lobortis quasi, eum.</p>
         <h6 className="mb-0 mt-4"><a className="button" href="#">Read More</a></h6>
       </div>
     </div>
   </div>
   <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
     <span className="carousel-control-prev-icon" aria-hidden="true"></span>
     <span className="visually-hidden">Previous</span>
   </button>
   <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
     <span className="carousel-control-next-icon" aria-hidden="true"></span>
     <span className="visually-hidden">Next</span>
   </button>
 </div>
</section>

<section id="center_top">
 <div className="container-xl">
   <div className="row">
     <div className="col-md-12"></div>
   </div>
 </div>
</section>

<section id="center_bottom">
 <div className="container-xl">
   <div className="row">
     <div className="col-md-12"></div>
   </div>
 </div>
</section>
</div> */}

        {/* <section id="about_h" className="p_3">
<div className="container-xl">
 <div className="about_hm position-relative">
   <div className="about_hm1">
     <div className="row about_h1">
       <div className="col-md-2">
         <div className="about_h1n"></div>
       </div>
       <div className="col-md-4">
         <div className="about_h1l shadow_box p-3 rounded-circle">
           <img src="https://www.truelymarry.com/assets/about/1.png" alt="abc" className="w-100 rounded-circle" />
         </div>
       </div>
       <div className="col-md-6">
         <div className="about_h1r">
           <h1 className="font_60">Lorem Amet</h1>
           <p className="mt-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna orci auctor vitae nisl. Erat fringilla pellentesque amet tempus. Commodo mi vitae, sed sagittis blandit. Leo netus magna fusce ac turpis mauris maecenas non</p>
           <ul className="mt-4 mb-0 social_tag">
             <li className="d-inline-block"><a href="#"><i className="fa-brands fa-facebook"></i></a></li>
             <li className="d-inline-block ms-1"><a href="#"><i className="fa-brands fa-instagram"></i></a></li>
             <li className="d-inline-block ms-1"><a href="#"><i className="fa-brands fa-twitter"></i></a></li>
             <li className="d-inline-block ms-1"><a href="#"><i className="fa-brands fa-pinterest"></i></a></li>
           </ul>
         </div>
       </div>
     </div>
     <div className="row about_h1 mt-4">
       <div className="col-md-6">
         <div className="about_h1r text-end">
           <h1 className="font_60">Dolor Porta</h1>
           <p className="mt-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna orci auctor vitae nisl. Erat fringilla pellentesque amet tempus. Commodo mi vitae, sed sagittis blandit. Leo netus magna fusce ac turpis mauris maecenas non</p>
           <ul className="mt-4 mb-0 social_tag">
             <li className="d-inline-block"><a href="#"><i className="fa-brands fa-facebook"></i></a></li>
             <li className="d-inline-block ms-1"><a href="#"><i className="fa-brands fa-instagram"></i></a></li>
             <li className="d-inline-block ms-1"><a href="#"><i className="fa-brands fa-twitter"></i></a></li>
             <li className="d-inline-block ms-1"><a href="#"><i className="fa-brands fa-pinterest"></i></a></li>
           </ul>
         </div>
       </div>
       <div className="col-md-4">
         <div className="about_h1l shadow_box p-3 rounded-circle">
           <img src="https://i1.adis.ws/i/canon/canon-pro-sanjay-eos-r-1-1140?w=1140&qlt=70&fmt=jpg&fmt.options=interlaced&bg=rgb(255,255,255)" alt="abc" className="w-100 rounded-circle" />
         </div>
       </div>
       <div className="col-md-2">
         <div className="about_h1n"></div>
       </div>
     </div>
   </div>
   <div className="about_hm2 position-absolute">
     <img src="https://rambank.in/wed/8.png" alt="About Image 1" />
   </div>
   <div className="about_hm3 position-absolute">
     <img src="https://rambank.in/wed/8.png" alt="About Image 2" />
   </div>
   <div className="about_hm4 position-absolute">
     <img src="https://rambank.in/wed/8.png" alt="About Image 3" />
   </div>
 </div>
</div>
</section> */}

        {/* <section id="story" className="p_3">
<div className="container-xl">
 <div className="row story_1 text-center mb-4">
   <div className="col-md-12">
     <span className="col_brown icon_dove fs-2"><i className="fa fa-dove"></i></span>
     <h1 className="font_50">Our Sweet Story</h1>
   </div>
 </div>
 <div className="row story_2 text-center">
   <div className="col-md-12">
     <ul className="timeline">
       <li>
         <div className="timeline-badge"><i className="fa fa-heart"></i></div>
         <div className="timeline-panel">
           <div className="timeline-heading">
             <h1 className="timeline-title">First Time We Meet</h1>
             <h6 className="mt-3 mb-3"><i className="fa fa-clock me-1 col_brown"></i> 19 Jan 2018</h6>
           </div>
           <div className="timeline-body">
             <p>Lorem ipsum dolor sit amet, constetur adicng elit. Ultricies nulla mi tempus mcorper for praesent. Ultricies interdum volutpat morbi nam ornare neque elit leo, diam. Malesuada enim ac amurna tempor vel duis.</p>
           </div>
         </div>
       </li>
       <li className="timeline-inverted">
         <div className="timeline-badge warning"><i className="fa fa-heart"></i></div>
         <div className="timeline-panel">
           <img alt="abc" className="w-100" src="/img/wedding/9.jpg" />
         </div>
       </li>
       <li>
         <div className="timeline-badge danger"><i className="fa fa-heart"></i></div>
         <div className="timeline-panel">
           <div className="timeline-heading">
             <h1 className="timeline-title">She Said Yes!</h1>
             <h6 className="mt-3 mb-3"><i className="fa fa-clock me-1 col_brown"></i> 22 Feb 2018</h6>
           </div>
           <div className="timeline-body">
             <p>Lorem ipsum dolor sit amet, constetur adicng elit. Ultricies nulla mi tempus mcorper for praesent. Ultricies interdum volutpat morbi nam ornare neque elit leo, diam. Malesuada enim ac amurna tempor vel duis.</p>
           </div>
         </div>
       </li>
       <li className="timeline-inverted">
         <div className="timeline-panel">
           <img alt="abc" className="w-100" src="/img/wedding/10.jpg" />
         </div>
       </li>
     </ul>
   </div>
 </div>
</div>
</section> */}

        {/* <!-- Bridesmaids & Groomsmen Section --> */}
        <section id="bride" className="p_3">
          <div className="container-xl">
            <div className="row story_1 text-center mb-4">
              <div className="col-md-12">
                <span className="col_brown icon_dove fs-2">
                  <i className="fa fa-dove"></i>
                </span>
                <h1 className="font_50">Bride & Groom</h1>
              </div>
            </div>
            <div className="row bride_1">
              <div className="col-md-4">
                <div className="bride_1i position-relative">
                  <div className="bride_1i1">
                    <img
                      src="/img/wedding/23.png"
                      alt="abc"
                      className="w-100"
                    />
                  </div>
                  <div className="bride_1i2 text-center position-absolute top-0 w-100 h-100">
                    <div className="about_h1l shadow_box p-3 rounded-circle">
                      <img
                        src="/img/wedding/21.jpg"
                        alt="abc"
                        className="w-100 rounded-circle"
                      />
                    </div>
                  </div>
                  <div className="bride_1i2n text-center">
                    <h1 className="mt-3">Lorem Porta</h1>
                    <h6 className="col_brown mb-0">Brides Brother</h6>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="bride_1i position-relative">
                  <div className="bride_1i1">
                    <img
                      src="/img/wedding/23.png"
                      alt="abc"
                      className="w-100"
                    />
                  </div>
                  <div className="bride_1i2 text-center position-absolute top-0 w-100 h-100">
                    <div className="about_h1l shadow_box p-3 rounded-circle">
                      <img
                        src="/img/wedding/20.jpg"
                        alt="abc"
                        className="w-100 rounded-circle"
                      />
                    </div>
                  </div>
                  <div className="bride_1i2n text-center">
                    <h1 className="mt-3">Eget Amet</h1>
                    <h6 className="col_brown mb-0">Brides Sister</h6>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="bride_1i position-relative">
                  <div className="bride_1i1">
                    <img
                      src="/img/wedding/23.png"
                      alt="abc"
                      className="w-100"
                    />
                  </div>
                  <div className="bride_1i2 text-center position-absolute top-0 w-100 h-100">
                    <div className="about_h1l shadow_box p-3 rounded-circle">
                      <img
                        src="/img/wedding/7.jpg"
                        alt="abc"
                        className="w-100 rounded-circle"
                      />
                    </div>
                  </div>
                  <div className="bride_1i2n text-center">
                    <h1 className="mt-3">Nulla Quis</h1>
                    <h6 className="col_brown mb-0">Brides Family</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="row bride_1 mt-4">
              <div className="col-md-4">
                <div className="bride_1i position-relative">
                  <div className="bride_1i1">
                    <img
                      src="/img/wedding/23.png"
                      alt="abc"
                      className="w-100"
                    />
                  </div>
                  <div className="bride_1i2 text-center position-absolute top-0 w-100 h-100">
                    <div className="about_h1l shadow_box p-3 rounded-circle">
                      <img
                        src="/img/wedding/19.jpg"
                        alt="abc"
                        className="w-100 rounded-circle"
                      />
                    </div>
                  </div>
                  <div className="bride_1i2n text-center">
                    <h1 className="mt-3">Lorem Porta</h1>
                    <h6 className="col_brown mb-0">Brides Brother</h6>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="bride_1i position-relative">
                  <div className="bride_1i1">
                    <img
                      src="/img/wedding/23.png"
                      alt="abc"
                      className="w-100"
                    />
                  </div>
                  <div className="bride_1i2 text-center position-absolute top-0 w-100 h-100">
                    <div className="about_h1l shadow_box p-3 rounded-circle">
                      <img
                        src="/img/wedding/22.jpg"
                        alt="abc"
                        className="w-100 rounded-circle"
                      />
                    </div>
                  </div>
                  <div className="bride_1i2n text-center">
                    <h1 className="mt-3">Eget Amet</h1>
                    <h6 className="col_brown mb-0">Brides Sister</h6>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="bride_1i position-relative">
                  <div className="bride_1i1">
                    <img
                      src="/img/wedding/23.png"
                      alt="abc"
                      className="w-100"
                    />
                  </div>
                  <div className="bride_1i2 text-center position-absolute top-0 w-100 h-100">
                    <div className="about_h1l shadow_box p-3 rounded-circle">
                      <img
                        src="/img/wedding/6.jpg"
                        alt="abc"
                        className="w-100 rounded-circle"
                      />
                    </div>
                  </div>
                  <div className="bride_1i2n text-center">
                    <h1 className="mt-3">Nulla Quis</h1>
                    <h6 className="col_brown mb-0">Brides Family</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="text-center">
          <div className="btn-group" role="group" aria-label="Basic example">
            <Link to={"/wedding/list"} className="button_1 ">
              View more
            </Link>
          </div>
        </div>

        {/* <!-- Moment Section --> */}
        <section id="moment" className="p_3">
          <div className="container-xl">
            <div className="row story_1 text-center mb-4">
              <div className="col-md-12">
                <span className="col_brown icon_dove fs-2">
                  <i className="fa fa-dove"></i>
                </span>
                <h1 className="font_50">Sweet Captured Moments</h1>
              </div>
            </div>
            <div className="row moment_1">
              <div className="col-md-4">
                <div className="moment_1l">
                  <div className="moment_1li position-relative">
                    <div className="moment_1li1">
                      <div className="grid clearfix">
                        <figure className="effect-jazz mb-0">
                          <a href="#">
                            <img
                              src="/img/wedding/12.jpg"
                              className="w-100"
                              alt="img25"
                            />
                          </a>
                        </figure>
                      </div>
                    </div>
                    <div className="moment_1li2 position-absolute text-center">
                      <span className="fs-2">
                        <a
                          data-bs-target="#exampleModal1"
                          data-bs-toggle="modal"
                          href="#"
                        >
                          <i className="fa fa-plus"></i>
                        </a>
                      </span>
                    </div>
                  </div>
                  <div
                    className="modal fade"
                    id="exampleModal1"
                    tabIndex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                    style={{ display: "none" }}
                  >
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="modal-body">
                          <img
                            src="/img/wedding/12.jpg"
                            className="w-100"
                            alt="abc"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="moment_1l mt-3">
                  <div className="moment_1li position-relative">
                    <div className="moment_1li1">
                      <div className="grid clearfix">
                        <figure className="effect-jazz mb-0">
                          <a href="#">
                            <img
                              src="/img/wedding/44.jpg"
                              className="w-100"
                              alt="img25"
                            />
                          </a>
                        </figure>
                      </div>
                    </div>
                    <div className="moment_1li2 position-absolute text-center moment_1li2o">
                      <span className="fs-2">
                        <a
                          data-bs-target="#exampleModal2"
                          data-bs-toggle="modal"
                          href="#"
                        >
                          <i className="fa fa-plus"></i>
                        </a>
                      </span>
                    </div>
                  </div>
                  <div
                    className="modal fade"
                    id="exampleModal2"
                    tabIndex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                    style={{ display: "none" }}
                  >
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="modal-body">
                          <img
                            src="/img/wedding/44.jpg"
                            className="w-100"
                            alt="abc"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="moment_1l">
                  <div className="moment_1li position-relative">
                    <div className="moment_1li1">
                      <div className="grid clearfix">
                        <figure className="effect-jazz mb-0">
                          <a href="#">
                            <img
                              src="/img/wedding/45.jpg"
                              className="w-100"
                              alt="img25"
                            />
                          </a>
                        </figure>
                      </div>
                    </div>
                    <div className="moment_1li2 position-absolute text-center moment_1li2o">
                      <span className="fs-2">
                        <a
                          data-bs-target="#exampleModal3"
                          data-bs-toggle="modal"
                          href="#"
                        >
                          <i className="fa fa-plus"></i>
                        </a>
                      </span>
                    </div>
                  </div>
                  <div
                    className="modal fade"
                    id="exampleModal3"
                    tabIndex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                    style={{ display: "none" }}
                  >
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="modal-body">
                          <img
                            src="/img/wedding/45.jpg"
                            className="w-100"
                            alt="abc"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="moment_1l mt-3">
                  <div className="moment_1li position-relative">
                    <div className="moment_1li1">
                      <div className="grid clearfix">
                        <figure className="effect-jazz mb-0">
                          <a href="#">
                            <img
                              src="/img/wedding/15.jpg"
                              className="w-100"
                              alt="img25"
                            />
                          </a>
                        </figure>
                      </div>
                    </div>
                    <div className="moment_1li2 position-absolute text-center">
                      <span className="fs-2">
                        <a
                          data-bs-target="#exampleModal4"
                          data-bs-toggle="modal"
                          href="#"
                        >
                          <i className="fa fa-plus"></i>
                        </a>
                      </span>
                    </div>
                  </div>
                  <div
                    className="modal fade"
                    id="exampleModal4"
                    tabIndex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                    style={{ display: "none" }}
                  >
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="modal-body">
                          <img
                            src="/img/wedding/15.jpg"
                            className="w-100"
                            alt="abc"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="moment_1l">
                  <div className="moment_1li position-relative">
                    <div className="moment_1li1">
                      <div className="grid clearfix">
                        <figure className="effect-jazz mb-0">
                          <a href="#">
                            <img
                              src="/img/wedding/16.jpg"
                              className="w-100"
                              alt="img25"
                            />
                          </a>
                        </figure>
                      </div>
                    </div>
                    <div className="moment_1li2 position-absolute text-center">
                      <span className="fs-2">
                        <a
                          data-bs-target="#exampleModal5"
                          data-bs-toggle="modal"
                          href="#"
                        >
                          <i className="fa fa-plus"></i>
                        </a>
                      </span>
                    </div>
                  </div>
                  <div
                    className="modal fade"
                    id="exampleModal5"
                    tabIndex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                    style={{ display: "none" }}
                  >
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="modal-body">
                          <img
                            src="/img/wedding/16.jpg"
                            className="w-100"
                            alt="abc"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="moment_1l mt-3">
                  <div className="moment_1li position-relative">
                    <div className="moment_1li1">
                      <div className="grid clearfix">
                        <figure className="effect-jazz mb-0">
                          <a href="#">
                            <img
                              src="/img/wedding/46.jpg"
                              className="w-100"
                              alt="img25"
                            />
                          </a>
                        </figure>
                      </div>
                    </div>
                    <div className="moment_1li2 position-absolute text-center moment_1li2o">
                      <span className="fs-2">
                        <a
                          data-bs-target="#exampleModal6"
                          data-bs-toggle="modal"
                          href="#"
                        >
                          <i className="fa fa-plus"></i>
                        </a>
                      </span>
                    </div>
                  </div>
                  <div
                    className="modal fade"
                    id="exampleModal6"
                    tabIndex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                    style={{ display: "none" }}
                  >
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="modal-body">
                          <img
                            src="/img/wedding/46.jpg"
                            className="w-100"
                            alt="abc"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Attend Section --> */}
        {/* <section id="attend" class="p_3">
<div class="container-xl">
<div class="row attend_1 text-center">
<div class="col-md-3">
<div class="attend_1l">
<div class="image-container">
<img src="/img/wedding/18.png" class="w-100" alt="abc" />
<img src="/img/wedding/wedding.gif" class="w-100 overlay" alt="abc" />
</div>

</div>
</div>
<div class="col-md-6">
<div class="attend_1m shadow_box">
<span class="col_brown icon_dove fs-2">
🕊
 <i class="fa fa-dove"></i></span>
<h1 class="font_50 mb-3">Are You Attending?</h1>
<input class="form-control" placeholder="Name" type="text"/>
<input class="form-control mt-3" placeholder="Email" type="text"/>
<div class="attend_1mi row mt-4">
  <div class="col-md-6">
    <div class="attend_1mil text-start">
      <div class="form-check">
        <input class="form-check-input" type="radio" name="attendance" id="attending" checked/>
        <label class="form-check-label" for="attending">
          Yes, I will be there
        </label>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="attend_1mil text-start">
      <div class="form-check">
        <input class="form-check-input" type="radio" name="attendance" id="notAttending"/>
        <label class="form-check-label" for="notAttending">
          Sorry, I can’t come
        </label>
      </div>
    </div>
  </div>
</div>

<select class="form-select form-control mt-5">
  <option selected>Number Of Guests</option>
  <option value="1">01</option>
  <option value="2">02</option>
  <option value="3">03</option>
  <option value="4">04</option>
</select>
<input class="form-control mt-3" placeholder="What Will You Be Attending" type="text"/>
<select class="form-select form-control mt-3">
  <option selected>Meal Preferences</option>
  <option value="1">Chicken Soup</option>
  <option value="2">Mix Salad</option>
  <option value="3">BBQ</option>
  <option value="4">Main Course</option>
</select>
<br/>
<h6 class="mb-0 mt-5"><a class="button_1" href="#">Send An Inquiry</a></h6>
</div>
</div>
<div class="col-md-3">
<div class="attend_1l">
<div class="image-container">
<img src="/img/wedding/18.png" class="w-100" alt="abc" />
<img src="/img/wedding/wedding.gif" class="w-100 overlay" alt="abc" />
</div>


</div>
</div>
</div>
</div>
</section> */}

        {/* <!-- Shop Section --> */}
        {/* <section id="shop_h" className="p_3">
<div className="container-xl">
<div className="row story_1 text-center mb-4">
<div className="col-md-12">
 <span className="col_brown icon_dove fs-2">
   <i className="fa fa-dove"></i>
 </span>
 <h1 className="font_50">Choose The Perfect Gift</h1>
</div>
</div>
<div className="row shop_h1 text-center">
<div className="col-md-3 col-sm-6">
 <div className="shop_h1l">
   <div className="shop_h1l1 position-relative">
     <div className="shop_h1l1i">
       <div className="grid clearfix">
         <figure className="effect-jazz mb-0">
           <a href="#">
             <img src="/img/wedding/24.jpg" className="w-100" alt="img25" />
           </a>
         </figure>
       </div>
     </div>
     <div className="shop_h1l1i1 position-absolute top-0 pt-3">
       <h6 className="mb-0 d-inline-block p-1 px-3 bg_brown text-white fst-italic">Sale</h6>
     </div>
   </div>
   <div className="shop_h1l2 mt-3">
     <h5 className="fw-normal">LOREM PORTA</h5>
     <h3 className="col_brown">
       <span className="text-decoration-line-through fs-6 text-muted fw-normal">$38</span> $29
     </h3>
     <h5 className="mb-0 fst-italic fw-normal">
       <a className="col_brown" href="#">Add to cart</a>
     </h5>
   </div>
 </div>
</div>
<div className="col-md-3 col-sm-6">
 <div className="shop_h1l">
   <div className="shop_h1l1 position-relative">
     <div className="shop_h1l1i">
       <div className="grid clearfix">
         <figure className="effect-jazz mb-0">
           <a href="#">
             <img src="/img/wedding/25.jpg" className="w-100" alt="img25" />
           </a>
         </figure>
       </div>
     </div>
     <div className="shop_h1l1i1 position-absolute top-0 pt-3">
       <h6 className="mb-0 d-inline-block p-1 px-3 bg_brown text-white fst-italic">Sale</h6>
     </div>
   </div>
   <div className="shop_h1l2 mt-3">
     <h5 className="fw-normal">IPSUM NULLA</h5>
     <h3 className="col_brown">
       <span className="text-decoration-line-through fs-6 text-muted fw-normal">$32</span> $23
     </h3>
     <h5 className="mb-0 fst-italic fw-normal">
       <a className="col_brown" href="#">Add to cart</a>
     </h5>
   </div>
 </div>
</div>
<div className="col-md-3 col-sm-6">
 <div className="shop_h1l">
   <div className="shop_h1l1 position-relative">
     <div className="shop_h1l1i">
       <div className="grid clearfix">
         <figure className="effect-jazz mb-0">
           <a href="#">
             <img src="/img/wedding/26.jpg" className="w-100" alt="img25" />
           </a>
         </figure>
       </div>
     </div>
     <div className="shop_h1l1i1 position-absolute top-0 pt-3">
       <h6 className="mb-0 d-inline-block p-1 px-3 bg_brown text-white fst-italic">Sale</h6>
     </div>
   </div>
   <div className="shop_h1l2 mt-3">
     <h5 className="fw-normal">DOLOR AMET</h5>
     <h3 className="col_brown">
       <span className="text-decoration-line-through fs-6 text-muted fw-normal">$42</span> $31
     </h3>
     <h5 className="mb-0 fst-italic fw-normal">
       <a className="col_brown" href="#">Add to cart</a>
     </h5>
   </div>
 </div>
</div>
<div className="col-md-3 col-sm-6">
 <div className="shop_h1l">
   <div className="shop_h1l1 position-relative">
     <div className="shop_h1l1i">
       <div className="grid clearfix">
         <figure className="effect-jazz mb-0">
           <a href="#">
             <img src="/img/wedding/27.jpg" className="w-100" alt="img25" />
           </a>
         </figure>
       </div>
     </div>
     <div className="shop_h1l1i1 position-absolute top-0 pt-3">
       <h6 className="mb-0 d-inline-block p-1 px-3 bg_brown text-white fst-italic">Sale</h6>
     </div>
   </div>
   <div className="shop_h1l2 mt-3">
     <h5 className="fw-normal">SED AUGUE</h5>
     <h3 className="col_brown">
       <span className="text-decoration-line-through fs-6 text-muted fw-normal">$47</span> $36
     </h3>
     <h5 className="mb-0 fst-italic fw-normal">
       <a className="col_brown" href="#">Add to cart</a>
     </h5>
   </div>
 </div>
</div>
</div>
<div className="row shop_h1 text-center mt-4">
<div className="col-md-3 col-sm-6">
 <div className="shop_h1l">
   <div className="shop_h1l1 position-relative">
     <div className="shop_h1l1i">
       <div className="grid clearfix">
         <figure className="effect-jazz mb-0">
           <a href="#">
             <img src="/img/wedding/28.jpg" className="w-100" alt="img25" />
           </a>
         </figure>
       </div>
     </div>
     <div className="shop_h1l1i1 position-absolute top-0 pt-3">
       <h6 className="mb-0 d-inline-block p-1 px-3 bg_brown text-white fst-italic">Sale</h6>
     </div>
   </div>
   <div className="shop_h1l2 mt-3">
     <h5 className="fw-normal">LOREM PORTA</h5>
     <h3 className="col_brown">
       <span className="text-decoration-line-through fs-6 text-muted fw-normal">$38</span> $29
     </h3>
     <h5 className="mb-0 fst-italic fw-normal">
       <a className="col_brown" href="#">Add to cart</a>
     </h5>
   </div>
 </div>
</div>
<div className="col-md-3 col-sm-6">
 <div className="shop_h1l">
   <div className="shop_h1l1 position-relative">
     <div className="shop_h1l1i">
       <div className="grid clearfix">
         <figure className="effect-jazz mb-0">
           <a href="#">
             <img src="/img/wedding/29.jpg" className="w-100" alt="img25" />
           </a>
         </figure>
       </div>
     </div>
     <div className="shop_h1l1i1 position-absolute top-0 pt-3">
       <h6 className="mb-0 d-inline-block p-1 px-3 bg_brown text-white fst-italic">Sale</h6>
     </div>
   </div>
   <div className="shop_h1l2 mt-3">
     <h5 className="fw-normal">IPSUM NULLA</h5>
     <h3 className="col_brown">
       <span className="text-decoration-line-through fs-6 text-muted fw-normal">$32</span> $23
     </h3>
     <h5 className="mb-0 fst-italic fw-normal">
       <a className="col_brown" href="#">Add to cart</a>
     </h5>
   </div>
 </div>
</div>
<div className="col-md-3 col-sm-6">
 <div className="shop_h1l">
   <div className="shop_h1l1 position-relative">
     <div className="shop_h1l1i">
       <div className="grid clearfix">
         <figure className="effect-jazz mb-0">
           <a href="#">
             <img src="/img/wedding/30.jpg" className="w-100" alt="img25" />
           </a>
         </figure>
       </div>
     </div>
     <div className="shop_h1l1i1 position-absolute top-0 pt-3">
       <h6 className="mb-0 d-inline-block p-1 px-3 bg_brown text-white fst-italic">Sale</h6>
     </div>
   </div>
   <div className="shop_h1l2 mt-3">
     <h5 className="fw-normal">DOLOR AMET</h5>
     <h3 className="col_brown">
       <span className="text-decoration-line-through fs-6 text-muted fw-normal">$42</span> $31
     </h3>
     <h5 className="mb-0 fst-italic fw-normal">
       <a className="col_brown" href="#">Add to cart</a>
     </h5>
   </div>
 </div>
</div>
<div className="col-md-3 col-sm-6">
 <div className="shop_h1l">
   <div className="shop_h1l1 position-relative">
     <div className="shop_h1l1i">
       <div className="grid clearfix">
         <figure className="effect-jazz mb-0">
           <a href="#">
             <img src="/img/wedding/31.jpg" className="w-100" alt="img25" />
           </a>
         </figure>
       </div>
     </div>
     <div className="shop_h1l1i1 position-absolute top-0 pt-3">
       <h6 className="mb-0 d-inline-block p-1 px-3 bg_brown text-white fst-italic">Sale</h6>
     </div>
   </div>
   <div className="shop_h1l2 mt-3">
     <h5 className="fw-normal">SED AUGUE</h5>
     <h3 className="col_brown">
       <span className="text-decoration-line-through fs-6 text-muted fw-normal">$47</span> $36
     </h3>
     <h5 className="mb-0 fst-italic fw-normal">
       <a className="col_brown" href="#">Add to cart</a>
     </h5>
   </div>
 </div>
</div>
</div>
</div>
</section> */}

        {/* <section id="price" className="p_3 px_4">
<div className="container-fluid">
<div className="price_m position-relative">
<div className="price_m1 position-absolute w-100 pt-5 top-0">
 <div className="row story_1 text-center mb-4">
   <div className="col-md-12">
     <span className="col_brown icon_dove fs-2">
       <i className="fa fa-dove"></i>
     </span>
     <h1 className="font_50">Wedding Packages</h1>
   </div>
 </div>
</div>
<div className="price_m2">
 <div className="price_1 row">
   <div className="col-md-12">
     <img className="w-100" src="/img/wedding/32.jpg" alt="abc" />
   </div>
 </div>
</div>
<div className="price_m3">
 <div className="price_2 row">
   <div className="col-md-4">
     <div className="price_2i text-center shadow_box p-4 bg-white">
       <h5 className="fw-normal">RELAX BASIC</h5>
       <h6 className="col_brown mt-3 fst-italic">
         $ <span className="fs-1">100</span> monthly
       </h6>
       <hr className="line mx-auto" />
       <ul>
         <li>Relaxing massage</li>
         <li className="mt-2">Manicure & Pedicure</li>
         <li className="mt-2">Body Polish</li>
         <li className="mt-2">Relaxing massage</li>
         <li className="mt-2">Other Services</li>
       </ul>
       <h6 className="mb-0 mt-4">
         <a className="button_1" href="#">Read More</a>
       </h6>
     </div>
   </div>
   <div className="col-md-4">
     <div className="price_2i text-center shadow_box p-4 bg-white">
       <h5 className="fw-normal">STANDARD</h5>
       <h6 className="col_brown mt-3 fst-italic">
         $ <span className="fs-1">200</span> monthly
       </h6>
       <hr className="line mx-auto" />
       <ul>
         <li>Relaxing massage</li>
         <li className="mt-2">Manicure & Pedicure</li>
         <li className="mt-2">Body Polish</li>
         <li className="mt-2">Relaxing massage</li>
         <li className="mt-2">Other Services</li>
       </ul>
       <h6 className="mb-0 mt-4">
         <a className="button_1" href="#">Read More</a>
       </h6>
     </div>
   </div>
   <div className="col-md-4">
     <div className="price_2i text-center shadow_box p-4 bg-white">
       <h5 className="fw-normal">EXCLUSIVE</h5>
       <h6 className="col_brown mt-3 fst-italic">
         $ <span className="fs-1">299</span> monthly
       </h6>
       <hr className="line mx-auto" />
       <ul>
         <li>Relaxing massage</li>
         <li className="mt-2">Manicure & Pedicure</li>
         <li className="mt-2">Body Polish</li>
         <li className="mt-2">Relaxing massage</li>
         <li className="mt-2">Other Services</li>
       </ul>
       <h6 className="mb-0 mt-4">
         <a className="button_1" href="#">Read More</a>
       </h6>
     </div>
   </div>
 </div>
</div>
</div>
</div>
</section> */}

        <div class="jaishree">
          <h1>
            <small>Day #10</small> Pricing Table
          </h1>
          <section>
            <div class="table">
              <div class="block">
                <div class="header">
                  <div class="heading">Basic</div>
                  <div class="price">
                    <span class="currency">$</span>
                    <span class="value">60</span>
                    <span class="duration">/mo</span>
                  </div>
                </div>
                <div class="body">
                  <ul class="features">
                    <li>
                      <strong>Primary</strong> Feature
                    </li>
                    <li>
                      <strong>Additional</strong> Benefit
                    </li>
                    <li>
                      <strong>1</strong> Bonus
                    </li>
                  </ul>
                </div>
                <div class="footer">
                  <a href="#" class="button">
                    Select
                  </a>
                </div>
              </div>
              <div class="block popular">
                <div class="header">
                  <div class="heading">Awesome</div>
                  <div class="price">
                    <span class="currency">$</span>
                    <span class="value">90</span>
                    <span class="duration">/mo</span>
                  </div>
                </div>
                <div class="body">
                  <ul class="features">
                    <li>
                      <strong>Primary</strong> Feature
                    </li>
                    <li>
                      <strong>Additional</strong> Benefit
                    </li>
                    <li>
                      <strong>2</strong> Bonuses
                    </li>
                    <li>
                      <strong>4</strong> Perks
                    </li>
                    <li>
                      <strong>24/7</strong> Support
                    </li>
                  </ul>
                </div>
                <div class="footer">
                  <a href="#" class="button">
                    Select
                  </a>
                </div>
              </div>
              <div class="block">
                <div class="header">
                  <div class="heading">Premium</div>
                  <div class="price">
                    <span class="currency">$</span>
                    <span class="value">120</span>
                    <span class="duration">/mo</span>
                  </div>
                </div>
                <div class="body">
                  <ul class="features">
                    <li>
                      <strong>Primary</strong> Feature
                    </li>
                    <li>
                      <strong>Additional</strong> Benefit
                    </li>
                    <li>
                      <strong>3</strong> Bonuses
                    </li>
                    <li>
                      <strong>5</strong> Perks
                    </li>
                    <li>
                      <strong>24/7</strong> Support
                    </li>
                  </ul>
                </div>
                <div class="footer">
                  <a href="#" class="button">
                    Select
                  </a>
                </div>
              </div>
            </div>
          </section>
          {/* <footer>
<a href="https://twitter.com/Lilykhan786" target="_blank">Lily.</a>
</footer> */}
        </div>
        <section id="serv_h" className="p_3">
          <div className="container-xl">
            <div className="row story_1 text-center mb-4">
              <div className="col-md-12">
                <span className="col_brown icon_dove fs-2">
                  <i className="fa fa-dove"></i>
                </span>
                <h1 className="font_50">Our Wedding Services</h1>
              </div>
            </div>
            <div className="row serv_h1">
              <div className="col-md-4">
                <div className="serv_h1i bg-white shadow_box p-2 text-center position-relative">
                  <div className="serv_h1i1 border_1 p-5 px-4 rounded-3">
                    <span className="rounded-circle font_60 col_brown">
                      <i className="fa fa-leaf"></i>
                    </span>
                    <h6 className="col_brown mt-4">Planning</h6>
                    <h4>
                      <a href="#">Lovely Decoration</a>
                    </h4>
                    <p className="mb-0">
                      We always be on time and ready to give you the great
                      service possible.
                    </p>
                  </div>
                  <div className="serv_h1i2 text-center position-absolute w-100">
                    <span>
                      <a
                        className="d-inline-block rounded-circle shadow_box"
                        href="#"
                      >
                        <i className="fa fa-arrow-right"></i>
                      </a>
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="serv_h1i bg-white shadow_box p-2 text-center position-relative">
                  <div className="serv_h1i1 border_1 p-5 px-4 rounded-3">
                    <span className="rounded-circle font_60 col_brown">
                      <i className="fa fa-users"></i>
                    </span>
                    <h6 className="col_brown mt-4">Wedding Stories</h6>
                    <h4>
                      <a href="#">Music and Dancing</a>
                    </h4>
                    <p className="mb-0">
                      We always be on time and ready to give you the great
                      service possible.
                    </p>
                  </div>
                  <div className="serv_h1i2 text-center position-absolute w-100">
                    <span>
                      <a
                        className="d-inline-block rounded-circle shadow_box"
                        href="#"
                      >
                        <i className="fa fa-arrow-right"></i>
                      </a>
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="serv_h1i bg-white shadow_box p-2 text-center position-relative">
                  <div className="serv_h1i1 border_1 p-5 px-4 rounded-3">
                    <span className="rounded-circle font_60 col_brown">
                      <i className="fa fa-glass-cheers"></i>
                    </span>
                    <h6 className="col_brown mt-4">Planning</h6>
                    <h4>
                      <a href="#">Dining & Drinks</a>
                    </h4>
                    <p className="mb-0">
                      We always be on time and ready to give you the great
                      service possible.
                    </p>
                  </div>
                  <div className="serv_h1i2 text-center position-absolute w-100">
                    <span>
                      <a
                        className="d-inline-block rounded-circle shadow_box"
                        href="#"
                      >
                        <i className="fa fa-arrow-right"></i>
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row serv_h2 mt-5 text-center">
              <div className="col-md-12">
                <p className="mb-0">
                  Don’t Hesitate, Contact Us For Better Help And Services.{" "}
                  <a className="col_brown fw-bold" href="#">
                    View More Services
                  </a>
                </p>
              </div>
            </div>
          </div>
        </section>
        <section id="blog_h" className="p_3">
          <div className="container-xl">
            <div className="row story_1 text-center mb-4">
              <div className="col-md-12">
                <span className="col_brown icon_dove fs-2">
                  <i className="fa fa-dove"></i>
                </span>
                <h1 className="font_50">Latest News & Blogs</h1>
              </div>
            </div>
            <div className="row blog_h1">
              <div className="col-md-4">
                <div className="blog_hm shadow_box">
                  <div className="blog_hm1 position-relative">
                    <div className="blog_hm1i">
                      <div className="grid clearfix">
                        <figure className="effect-jazz mb-0">
                          <a href="#">
                            <img
                              src="/img/wedding/13.jpg"
                              className="w-100"
                              alt="img25"
                            />
                          </a>
                        </figure>
                      </div>
                    </div>

                    <div className="blog_hm1i1 p-3 position-absolute top-0">
                      {/* <h6 className="mb-0 d-inline-block bg_brown text-white p-2 px-3 text-center">
                <span className="fw-bold">17</span> <br /> JUL
              </h6> */}
                    </div>
                  </div>
                  <div className="blog_hm2 p-4">
                    <ul className="font_13">
                      <li className="d-inline-block">
                        <i className="fa fa-folder col_brown me-1 align-middle"></i>{" "}
                        Dj Night
                      </li>
                      <li className="d-inline-block mx-2 text-muted">|</li>
                      <li className="d-inline-block">
                        <i className="fa fa-user col_brown me-1 align-middle"></i>{" "}
                        Nulla Quis
                      </li>
                    </ul>
                    <h4>
                      <a href="#">
                        Fall wedding colors might be the best wedding
                      </a>
                    </h4>
                    <p>
                      Monsoon is almost here and we are just can’t wait for ‘em
                      showers to give us.
                    </p>
                    <h6 className="mb-0 mt-4">
                      <a className="button_1" href="#">
                        Read More
                      </a>
                    </h6>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="blog_hm shadow_box">
                  <div className="blog_hm1 position-relative">
                    <div className="blog_hm1i">
                      <div className="grid clearfix">
                        <figure className="effect-jazz mb-0">
                          <a href="#">
                            <img
                              src="/img/wedding/14.jpg"
                              className="w-100"
                              alt="img25"
                            />
                          </a>
                        </figure>
                      </div>
                    </div>
                    <div className="blog_hm1i1 p-3 position-absolute top-0">
                      {/* <h6 className="mb-0 d-inline-block bg_brown text-white p-2 px-3 text-center">
                <span className="fw-bold">18</span> <br /> JUL
              </h6> */}
                    </div>
                  </div>
                  <div className="blog_hm2 p-4">
                    <ul className="font_13">
                      <li className="d-inline-block">
                        <i className="fa fa-folder col_brown me-1 align-middle"></i>{" "}
                        Dancing
                      </li>
                      <li className="d-inline-block mx-2 text-muted">|</li>
                      <li className="d-inline-block">
                        <i className="fa fa-user col_brown me-1 align-middle"></i>{" "}
                        Sem Augue
                      </li>
                    </ul>
                    <h4>
                      <a href="#">Special Benefit Through Est Be Your Money</a>
                    </h4>
                    <p>
                      Monsoon is almost here and we are just can’t wait for ‘em
                      showers to give us.
                    </p>
                    <h6 className="mb-0 mt-4">
                      <a className="button_1" href="#">
                        Read More
                      </a>
                    </h6>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="blog_hm shadow_box">
                  <div className="blog_hm1 position-relative">
                    <div className="blog_hm1i">
                      <div className="grid clearfix">
                        <figure className="effect-jazz mb-0">
                          <a href="#">
                            <img
                              src="/img/wedding/17.jpg"
                              className="w-100"
                              alt="img25"
                            />
                          </a>
                        </figure>
                      </div>
                    </div>
                    <div className="blog_hm1i1 p-3 position-absolute top-0">
                      {/* <h6 className="mb-0 d-inline-block bg_brown text-white p-2 px-3 text-center">
                <span className="fw-bold">15</span> <br /> JUL
              </h6> */}
                    </div>
                  </div>
                  <div className="blog_hm2 p-4">
                    <ul className="font_13">
                      <li className="d-inline-block">
                        <i className="fa fa-folder col_brown me-1 align-middle"></i>{" "}
                        Catering
                      </li>
                      <li className="d-inline-block mx-2 text-muted">|</li>
                      <li className="d-inline-block">
                        <i className="fa fa-user col_brown me-1 align-middle"></i>{" "}
                        Dolor Amet
                      </li>
                    </ul>
                    <h4>
                      <a href="#">
                        Tips for Making Business Catering Affordable
                      </a>
                    </h4>
                    <p>
                      Monsoon is almost here and we are just can’t wait for ‘em
                      showers to give us.
                    </p>
                    <h6 className="mb-0 mt-4">
                      <a className="button_1" href="#">
                        Read More
                      </a>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="row blog_h1 mt-4">
              <div className="col-md-4">
                <div className="blog_hm shadow_box">
                  <div className="blog_hm1 position-relative">
                    <div className="blog_hm1i">
                      <div className="grid clearfix">
                        <figure className="effect-jazz mb-0">
                          <a href="#">
                            <img
                              src="/img/wedding/10.jpg"
                              className="w-100"
                              alt="img25"
                            />
                          </a>
                        </figure>
                      </div>
                    </div>
                    <div className="blog_hm1i1 p-3 position-absolute top-0">
                      {/* <h6 className="mb-0 d-inline-block bg_brown text-white p-2 px-3 text-center">
                <span className="fw-bold">17</span> <br /> JUL
              </h6> */}
                    </div>
                  </div>
                  <div className="blog_hm2 p-4">
                    <ul className="font_13">
                      <li className="d-inline-block">
                        <i className="fa fa-folder col_brown me-1 align-middle"></i>{" "}
                        Dj Night
                      </li>
                      <li className="d-inline-block mx-2 text-muted">|</li>
                      <li className="d-inline-block">
                        <i className="fa fa-user col_brown me-1 align-middle"></i>{" "}
                        Nulla Quis
                      </li>
                    </ul>
                    <h4>
                      <a href="#">
                        Fall wedding colors might be the best wedding
                      </a>
                    </h4>
                    <p>
                      Monsoon is almost here and we are just can’t wait for ‘em
                      showers to give us.
                    </p>
                    <h6 className="mb-0 mt-4">
                      <a className="button_1" href="#">
                        Read More
                      </a>
                    </h6>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="blog_hm shadow_box">
                  <div className="blog_hm1 position-relative">
                    <div className="blog_hm1i">
                      <div className="grid clearfix">
                        <figure className="effect-jazz mb-0">
                          <a href="#">
                            <img
                              src="/img/wedding/9.jpg"
                              className="w-100"
                              alt="img25"
                            />
                          </a>
                        </figure>
                      </div>
                    </div>
                    <div className="blog_hm1i1 p-3 position-absolute top-0">
                      {/* <h6 className="mb-0 d-inline-block bg_brown text-white p-2 px-3 text-center">
                <span className="fw-bold">18</span> <br /> JUL
              </h6> */}
                    </div>
                  </div>
                  <div className="blog_hm2 p-4">
                    <ul className="font_13">
                      <li className="d-inline-block">
                        <i className="fa fa-folder col_brown me-1 align-middle"></i>{" "}
                        Dancing
                      </li>
                      <li className="d-inline-block mx-2 text-muted">|</li>
                      <li className="d-inline-block">
                        <i className="fa fa-user col_brown me-1 align-middle"></i>{" "}
                        Sem Augue
                      </li>
                    </ul>
                    <h4>
                      <a href="#">Special Benefit Through Est Be Your Money</a>
                    </h4>
                    <p>
                      Monsoon is almost here and we are just can’t wait for ‘em
                      showers to give us.
                    </p>
                    <h6 className="mb-0 mt-4">
                      <a className="button_1" href="#">
                        Read More
                      </a>
                    </h6>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="blog_hm shadow_box">
                  <div className="blog_hm1 position-relative">
                    <div className="blog_hm1i">
                      <div className="grid clearfix">
                        <figure className="effect-jazz mb-0">
                          <a href="#">
                            <img
                              src="/img/wedding/35.jpg"
                              className="w-100"
                              alt="img25"
                            />
                          </a>
                        </figure>
                      </div>
                    </div>
                    <div className="blog_hm1i1 p-3 position-absolute top-0">
                      {/* <h6 className="mb-0 d-inline-block bg_brown text-white p-2 px-3 text-center">
                <span className="fw-bold">15</span> <br /> JUL
              </h6> */}
                    </div>
                  </div>
                  <div className="blog_hm2 p-4">
                    <ul className="font_13">
                      <li className="d-inline-block">
                        <i className="fa fa-folder col_brown me-1 align-middle"></i>{" "}
                        Catering
                      </li>
                      <li className="d-inline-block mx-2 text-muted">|</li>
                      <li className="d-inline-block">
                        <i className="fa fa-user col_brown me-1 align-middle"></i>{" "}
                        Dolor Amet
                      </li>
                    </ul>
                    <h4>
                      <a href="#">
                        Tips for Making Business Catering Affordable
                      </a>
                    </h4>
                    <p>
                      Monsoon is almost here and we are just can’t wait for ‘em
                      showers to give us.
                    </p>
                    <h6 className="mb-0 mt-4">
                      <a className="button_1" href="#">
                        Read More
                      </a>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section id="team" className="p_3">
<div className="container-xl">
<div className="row story_1 text-center mb-4">
<div className="col-md-12">
<span className="col_brown icon_dove fs-2"><i className="fa fa-dove"></i></span>
<h1 className="font_50">Our Event Coordinators</h1>
</div>
</div>
<div className="row team_h1">
<div className="col-md-4">
<div className="team_h1i">
  <div className="team_h1i1 position-relative">
    <div className="team_h1i1i">
      <div className="grid clearfix">
        <figure className="effect-jazz mb-0">
          <a href="#"><img src="/img/wedding/37.jpg" className="w-100" alt="img25" /></a>
        </figure>
      </div>
    </div>
    <div className="team_h1i1i1 text-end position-absolute w-100 bottom-0 px-4">
      <ul className="mb-0">
        <li><a className="d-inline-block bg_brown text-center text-white" href="#"><i className="fa-brands fa-facebook"></i></a></li>
        <li className="mt-2"><a className="d-inline-block bg_brown text-center text-white" href="#"><i className="fa-brands fa-instagram"></i></a></li>
        <li className="mt-2"><a className="d-inline-block bg_brown text-center text-white" href="#"><i className="fa-brands fa-pinterest"></i></a></li>
      </ul>
    </div>
  </div>
  <div className="team_h1i2 shadow_box p-4">
    <div className="team_h1i2i row">
      <div className="col-md-9">
        <div className="team_h1i2il">
          <h6>Event Coordinator</h6>
          <h4 className="mb-0"><a href="#">Cras Aures</a></h4>
        </div>
      </div>
      <div className="col-md-3">
        <div className="team_h1i2ir mt-3 text-end">
          <span><a className="d-inline-block border_1 text-center" href="#"><i className="fa fa-plus"></i></a></span>
        </div>
      </div>
    </div> 
  </div>
</div>
</div>
<div className="col-md-4">
<div className="team_h1i">
  <div className="team_h1i1 position-relative">
    <div className="team_h1i1i">
      <div className="grid clearfix">
        <figure className="effect-jazz mb-0">
          <a href="#"><img src="/img/wedding/38.jpg" className="w-100" alt="img25" /></a>
        </figure>
      </div>
    </div>
    <div className="team_h1i1i1 text-end position-absolute w-100 bottom-0 px-4">
      <ul className="mb-0">
        <li><a className="d-inline-block bg_brown text-center text-white" href="#"><i className="fa-brands fa-facebook"></i></a></li>
        <li className="mt-2"><a className="d-inline-block bg_brown text-center text-white" href="#"><i className="fa-brands fa-instagram"></i></a></li>
        <li className="mt-2"><a className="d-inline-block bg_brown text-center text-white" href="#"><i className="fa-brands fa-pinterest"></i></a></li>
      </ul>
    </div>
  </div>
  <div className="team_h1i2 shadow_box p-4">
    <div className="team_h1i2i row">
      <div className="col-md-9">
        <div className="team_h1i2il">
          <h6>Event Coordinator</h6>
          <h4 className="mb-0"><a href="#">Donec Elir</a></h4>
        </div>
      </div>
      <div className="col-md-3">
        <div className="team_h1i2ir mt-3 text-end">
          <span><a className="d-inline-block border_1 text-center" href="#"><i className="fa fa-plus"></i></a></span>
        </div>
      </div>
    </div> 
  </div>
</div>
</div>
<div className="col-md-4">
<div className="team_h1i">
  <div className="team_h1i1 position-relative">
    <div className="team_h1i1i">
      <div className="grid clearfix">
        <figure className="effect-jazz mb-0">
          <a href="#"><img src="/img/wedding/39.jpg" className="w-100" alt="img25" /></a>
        </figure>
      </div>
    </div>
    <div className="team_h1i1i1 text-end position-absolute w-100 bottom-0 px-4">
      <ul className="mb-0">
        <li><a className="d-inline-block bg_brown text-center text-white" href="#"><i className="fa-brands fa-facebook"></i></a></li>
        <li className="mt-2"><a className="d-inline-block bg_brown text-center text-white" href="#"><i className="fa-brands fa-instagram"></i></a></li>
        <li className="mt-2"><a className="d-inline-block bg_brown text-center text-white" href="#"><i className="fa-brands fa-pinterest"></i></a></li>
      </ul>
    </div>
  </div>
  <div className="team_h1i2 shadow_box p-4">
    <div className="team_h1i2i row">
      <div className="col-md-9">
        <div className="team_h1i2il">
          <h6>Event Coordinator</h6>
          <h4 className="mb-0"><a href="#">Sed Ligula</a></h4>
        </div>
      </div>
      <div className="col-md-3">
        <div className="team_h1i2ir mt-3 text-end">
          <span><a className="d-inline-block border_1 text-center" href="#"><i className="fa fa-plus"></i></a></span>
        </div>
      </div>
    </div> 
  </div>
</div>
</div>
</div>
</div>
</section> */}
      </div>
    </div>
  );
}

export default Wedding;
